import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

import { firebaseConfig } from './Config'

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()

// firestore.settings({ timestampsInSnapshots: true })

export const auth = firebase.auth()
export const storage = firebase.storage()
export const functions = firebase.functions()

export default firebase
