import React, { useContext, useEffect } from 'react'

import firebase, { auth, firestore } from '../common/firebase'

import { Router, navigate } from '@reach/router'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

import Button from '@material-ui/core/Button'
import Tabs from '@material-ui/core/Tabs'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import HomeIcon from '@material-ui/icons/Home'

import { StoreContext } from '../contexts/StoreProvider'
import StylesContext from '../contexts/StylesContext'

import LangContext from '../contexts/LangContext'
import CheckElement from '../components/CheckElement'
import SProgress from '../components/SProgress'
import Wrapper from '../components/Wrapper'

import { getAccessData, ACTIVE_TRACKER } from '../common/Config'
import { dateUTCPlus1 } from '../common/Utils'

function BackOffice (props) {
  const { store, dispatchStore } = useContext(StoreContext)

  const [loadState, setLoadState] = React.useState('PEN') //'PEN', 'DONE', 'ERROR'

  useEffect(() => {
    async function loadConfig () {
      let getConfig = firebase.functions().httpsCallable('getConfig')
      try {
        let configData = await getConfig()

        let permissionsMap = getAccessData(configData.data.selectedUser)

        dispatchStore({
          type: 'setUser',
          payload: {
            userapp: configData.data.userapp,
            client: configData.data.client,
            permissionsMap: permissionsMap,
            currentTimeStamp: configData.data.currentTimeStamp
          }
        })
      } catch (err) {
        throw err
      }
    } //loadConfig

    let unsubscribe = auth.onAuthStateChanged(function (user) {
      if (user) {
        loadConfig({})
          .then(res => {
            setLoadState('DONE') //load children components
            //navigate('/backoffice')
          })
          .catch(err => {
            console.log('error', err)
            setLoadState('ERROR')
          })
      } else {
        dispatchStore({ type: 'reset' })
        navigate('/login')
      }
    })

    return function cleanup () {
      if (unsubscribe) unsubscribe()
    }
  }, [])

  console.log('store', store)
  /*console.log(
    'store.currentTimeStap',
    new Date(new Date(store.currentTimeStamp).getTime())
  )*/

  return (
    <div>
      <AppBar position='fixed'>Hola</AppBar>
      <div>
        <Wrapper visible={loadState === 'PEN'}>
          <DataLoading />
        </Wrapper>
        <Wrapper visible={loadState === 'DONE'}>{props.children}</Wrapper>
        <Wrapper visible={loadState === 'ERROR'}>
          <ErrorLoading />
        </Wrapper>
      </div>
    </div>
  )
}

export default BackOffice

function DataLoading ({}) {
  return (
    <div
      style={{
        position: 'relative',

        textAlign: 'center'
      }}
    >
      <h1>...{'CARGANDO'}</h1>
      <SProgress visible size={80} />
    </div>
  )
} // DataLoading

function ErrorLoading ({}) {
  const [styles] = useContext(StylesContext)
  const [lang, setSelectedLand, selectedLang] = useContext(LangContext)
  let APP_NAME = lang.APP_NAME
  let MENU_PROJECTS = lang.MENU_PROJECTS
  let MENU_REPORTS = lang.MENU_REPORTS
  let MENU_CONFIGURATION = lang.MENU_CONFIGURATION
  let ERROR_LOADING = lang.ERROR_LOADING

  return (
    <div
      style={{
        position: 'relative',
        color: styles.primaryColor,
        textAlign: 'center'
      }}
    >
      <h1>{ERROR_LOADING}</h1>
    </div>
  )
} // ErrorLoading
