import React, { useContext } from 'react'

function Privacy () {
  return (
    <div style={{ padding: '20px', color: 'rgb(179,43,129)' }}>
      <h2>POLÍTICA DE PRIVACIDAD</h2>
      <p>
        JOSE COEGO MIGUÉLEZ te informa sobre su Política de Privacidad respecto
        del tratamiento y protección de los datos de carácter personal de los
        usuarios y clientes que puedan ser recabados por la navegación o
        contratación de servicios a través del sitio Web
        https://app.estetica40.com.
      </p>
      <p>
        En este sentido, el Titular garantiza el cumplimiento de la normativa
        vigente en materia de protección de datos personales, reflejada en la
        Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos
        Personales y de Garantía de Derechos Digitales (LOPD GDD). Cumple
        también con el Reglamento (UE) 2016/679 del Parlamento Europeo y del
        Consejo de 27 de abril de 2016 relativo a la protección de las personas
        físicas (RGPD).
      </p>
      <p>
        El uso de sitio Web implica la aceptación de esta Política de Privacidad
        así como las condiciones incluidas en el Aviso Legal.
      </p>

      <h3>Identidad del responsable</h3>
      <ul>
        <li>Titular: Jose Coego Miguélez</li>
        <li>NIF/CIF: 44088934G</li>
        <li>Domicilio: Lg. Bais, nº2, Agolada (Pontevedra)</li>
        <li>Correo electrónico: jcoegom@gmail.com</li>
        <li>Sitio Web: https://app.estetica40.com</li>
      </ul>

      <h3>Principios aplicados en el tratamiento de datos</h3>
      <p>
        En el tratamiento de tus datos personales, el Titular aplicará los
        siguientes principios que se ajustan a las exigencias del nuevo
        reglamento europeo de protección de datos:
      </p>
      <ul>
        <li>
          Principio de licitud, lealtad y transparencia: El Titular siempre
          requerirá el consentimiento para el tratamiento de tus datos
          personales que puede ser para uno o varios fines específicos sobre los
          que te informará previamente con absoluta transparencia.
        </li>
        <li>
          Principio de minimización de datos: El Titular te solicitará solo los
          datos estrictamente necesarios para el fin o los fines que los
          solicita.
        </li>
        <li>
          Principio de limitación del plazo de conservación: Los datos se
          mantendrán durante el tiempo estrictamente necesario para el fin o los
          fines del tratamiento. El Titular te informará del plazo de
          conservación correspondiente según la finalidad. En el caso de
          suscripciones, el Titular revisará periódicamente las listas y
          eliminará aquellos registros inactivos durante un tiempo considerable.
        </li>
        <li>
          Principio de integridad y confidencialidad: Tus datos serán tratados
          de tal manera que su seguridad, confidencialidad e integridad esté
          garantizada. Debes saber que el Titular toma las precauciones
          necesarias para evitar el acceso no autorizado o uso indebido de los
          datos de sus usuarios por parte de terceros.
        </li>
      </ul>

      <h3>Obtención de datos personales</h3>
      <p>Los datos personales solicitados son los siguientes:</p>

      <ul>
        <li>
          Nombre, email y teléfono (no obligatorio) en el caso de usar
          formularios de contacto para obtener algún tipo de información sobre
          el servicio o al realizar alguna compra.
        </li>
        <li>
          Dirección de email para poder registrarse en la plataforma y operar
          con las funcionalidades soportadas en la misma
        </li>
      </ul>

      <h3>Tus derechos</h3>
      <p>
        El Titular te informa que sobre tus datos personales tienes derecho a:
      </p>
      <ul>
        <li>Solicitar el acceso a los datos almacenados.</li>
        <li>Solicitar una rectificación o la cancelación.</li>
        <li>Solicitar la limitación de su tratamiento</li>
        <li>Oponerte al tratamiento.</li>
        <li>Solicitar la portabilidad de tus datos.</li>
      </ul>
      <p>
        El ejercicio de estos derechos es personal y por tanto debe ser ejercido
        directamente por el interesado, solicitándolo directamente al Titular,
        lo que significa que cualquier cliente, suscriptor o colaborador que
        haya facilitado sus datos en algún momento puede dirigirse al Titular y
        pedir información sobre los datos que tiene almacenados y cómo los ha
        obtenido, solicitar la rectificación de los mismos, solicitar la
        portabilidad de sus datos personales, oponerse al tratamiento, limitar
        su uso o solicitar la cancelación de esos datos en los ficheros del
        Titular.
      </p>
      <p>
        Para ejercitar tus derechos de acceso, rectificación, cancelación,
        portabilidad y oposición tienes que enviar un correo electrónico a
        semaforocolesanticovid@gmail.com junto con la prueba válida en derecho
        como una fotocopia del D.N.I. o equivalente.
      </p>
      <p>
        Tienes derecho a la tutela judicial efectiva y a presentar una
        reclamación ante la autoridad de control, en este caso, la Agencia
        Española de Protección de Datos, si consideras que el tratamiento de
        datos personales que te conciernen infringe el Reglamento.
      </p>

      <h3>Finalidad del tratamiento de datos personales</h3>
      <p>
        Los datos personales y la finalidad del tratamiento de los mismos es el
        siguiente:
      </p>
      <ul>
        <li>
          Formularios de contacto: Se solicitan Nombre, dirección de correo
          electrónico, número de teléfono con la finalidad de ponerse en
          contacto con usted para responder a tus consultas, dudas, quejas
          inquietudes relativas a la web o al servicio prestado.
        </li>
        <li>
          Datos de login/registro: Se solicita la dirección tu dirección de mail
          y una password. Permite el acceso a los servicios contratados. La
          razón de incluir una dirección de mail y no un “nombre de usuario” es
          que en el caso de que sea necesario recuperar la password se
          realizaría a través del envío a la dirección de mail de un enlace con
          dicha funcionalidad.
        </li>
      </ul>
      <h3>Seguridad de los datos personales</h3>
      <p>
        Para proteger tus datos personales, el Titular toma todas las
        precauciones razonables y sigue las mejores prácticas de la industria
        para evitar su pérdida, mal uso, acceso indebido, divulgación,
        alteración o destrucción de los mismos.
      </p>
      <p>
        El sitio Web está alojado en “firebase.google.com”. La seguridad de tus
        datos está garantizada, ya que toman todas las medidas de seguridad
        necesarias para ello. Puedes consultar su política de privacidad para
        tener más información en el siguiente enlace:
        <a
          href='https://firebase.google.com/support/privacy?hl=es-419'
          target='_blank'
        >
          https://firebase.google.com/support/privacy?hl=es-419
        </a>
      </p>

      <h3>Política de Cookies</h3>
      <p>
        Para que este sitio Web funcione correctamente necesita utilizar
        cookies, que es una información que se almacena en tu navegador web.
      </p>
      <p>
        En la página Política de Cookies puedes consultar toda la información
        relativa a la política de recogida, la finalidad y el tratamiento de las
        cookies.
      </p>

      <h3>Categorías de datos personales</h3>
      <p>Las categorías de datos personales que trata el Titular son:</p>
      <ul>
        <li>Datos identificativos.</li>
      </ul>

      <h3>Conservación de datos personales</h3>
      <p>
        Los datos personales que proporciones al Titular se conservarán hasta
        que solicites su supresión.
      </p>
      <h3>Navegación Web</h3>
      <p>
        Al navegar por www.semaforo.colesanticovid.es se pueden recoger datos no
        identificativos, que pueden incluir, la dirección IP, geolocalización,
        un registro de cómo se utilizan los servicios y sitios, hábitos de
        navegación y otros datos que no pueden ser utilizados para
        identificarte.
      </p>
      <p>El sitio Web NO utiliza servicios de análisis de terceros.</p>
      <p>
        El Titular utiliza la información obtenida para obtener datos
        estadísticos, analizar tendencias, administrar el sitio, estudiar
        patrones de navegación y para recopilar información demográfica.
      </p>

      <h3>Exactitud y veracidad de los datos personales</h3>
      <p>
        Te comprometes a que los datos facilitados al Titular sean correctos,
        completos, exactos y vigentes, así como a mantenerlos debidamente
        actualizados.
      </p>
      <p>
        Como Usuario del sitio Web eres el único responsable de la veracidad y
        corrección de los datos que remitas al sitio exonerando a el Titular de
        cualquier responsabilidad al respecto.
      </p>

      <h3>Revocabilidad</h3>
      <p>
        Para ejercitar tus derechos de acceso, rectificación, cancelación,
        portabilidad y oposición tienes que enviar un correo electrónico a
        semaforocolesanticovid@gmail.com junto con la prueba válida en derecho
        como una fotocopia del D.N.I. o equivalente.
      </p>
      <p>
        El ejercicio de tus derechos no incluye ningún dato que el Titular esté
        obligado a conservar con fines administrativos, legales o de seguridad.
      </p>
    </div>
  )
} //Legal

export default Privacy
