import React, { useContext, useEffect, useState } from 'react'

import firebase, { auth, firestore } from '../common/firebase'

import { Router, navigate } from '@reach/router'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { StoreContext } from '../contexts/StoreProvider'

import useCrud from '../hooks/UseCrud'
import useCheck from '../hooks/UseCheck'
import useFeedback from '../hooks/UseFeedBack'
import useProgress from '../hooks/UseProgress'
import SProgress from '../components/SProgress'
import LayoutBOContent from '../components/LayoutBOContent'
import Feedback from '../components/Feedback';
import useTracker, { CLICK_BUY_MINIBOOKS } from '../hooks/useTracker'

import SGrid from '../components/SGrid'
import Wrapper from '../components/Wrapper'

import Book from './minibooks/Book'

const defaultState = {
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

const defaultMinibooks = []

function MiniBooks(props) {
  const { store, dispatchStore, actions, func } = useContext(StoreContext);
  const [handleTracker] = useTracker(store.activeTracker);

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)
  const [minibooks, setMinibooks] = useState(defaultMinibooks)

  const [checkIsState, handleCheckState] = useCheck({
    conditions: [
      {
        name: state.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: state.capacity,
        errorName: 'capacity',
        check: ['required', 'number', ['>', 0, 'field_bigger_0']]
      },
      {
        name: state.userappMail,
        errorName: 'userappMail',
        check: ['required', ['function', () => { }, state.param]]
      }
    ],
    setErrorState: setStateError,
    errorState: {},
    defaultErrorState: defaultStateError
  })

  const [crudMinibooks] = useCrud({
    arrCol: ['/minibooks/']
  })

  useEffect(() => {
    getMinibooks()
  }, [])

  const getMinibooks = async () => {
    let minibooksRef = crudMinibooks.findDocRef({})
    let minibooksData = await minibooksRef.where('status', '==', 1).get()
    let minibooksAux = crudMinibooks.getResult({ data: minibooksData })
    setMinibooks([...minibooksAux])
  } //getMinibooks

  async function handleClick() {
    //create/upate resource
    let check = handleCheckState()
    if (check) {
      feedback.error(store.lang['USER_DATA_ERROR'])
      return
    }

    try {
      showProgress()
      let batch = firestore.batch()

      crudMinibooks.create({
        batch,
        payload: {}
      })

      batch
        .commit()
        .then(res => {
          feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (err) {
      handleGlobalError({
        error: err,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    } finally {
      hideProgress()
    }
  } //handleClickCreateResource

  async function handleClickBook(_in) {
    switch (_in.action) {
      case 'BUY':
        let res = func.addItemToCart(_in.book)
        if (res === 'already_added') {
          feedbackFunc.info(store.lang['ALREADY_ADDED'])
        }
        if (res === 'done') {
          feedbackFunc.info(store.lang['ADDED_OK_TO_CART'])
        }
        break
    }
  } //handleClickBook

  return (
    <div style={{ backgroundColor: '#e4b9db' }}>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      {/*<DialogDeleteConfirm
        open={dialogConfirm.showModal}
        code={dialogConfirm.code}
        onClick={({ type, code, payload }) => {
          if (type === 'CANCEL') {
          }
          if (type === 'OK') {
            switch (code) {
              case 'deleteGuest':
                handleDeleteGuest(dialogConfirm.payload)
                break
              case 'deleteResource':
                handleDeleteResource(dialogConfirm.payload)
                break
            }
          }
          setDialogConfirm(defaultDialogConfirm)
        }}
      />*/}
      <br />
      <br />
      <br />
      <br />
      <Grid container style={{ width: '100%' }} justify='center' spacing={4}>
        {minibooks &&
          minibooks.map(book => (
            <Grid key={book.id} item>
              <Book
                title={book.title}
                description={book.description}
                price={book.price}
                imgSrc={book.imgSrc}
                onClick={data => {
                  if (data.action === 'VIEW_DETAILS')
                    navigate('/minibooks/' + book.slug)
                  if (data.action === 'BUY') {
                    handleTracker(CLICK_BUY_MINIBOOKS, { book })
                    handleClickBook({ ...data, book: book });
                  }
                }}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default MiniBooks
