import React, { useContext } from 'react'

import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'

import { StoreContext } from '../../../contexts/StoreProvider'

import { interpolateString } from '../../../common/Utils'

function MinibooksPaymentsView ({
  email = '',
  products = [],
  onClick = () => {}
}) {
  const { store, dispatchStore } = useContext(StoreContext)

  const divStyles = {
    backgroundColor: store.styles.secondaryColor,
    color: 'white',
    fontWeight: 'bold',
    padding: '15px'
    //borderRadius: '5px'
  }

  return (
    <div style={{ margin: '15px', textAlign: 'center' }}>
      <div
        style={{
          ...divStyles,
          borderTopLeftRadius: '5px',
          borderTopRightRadius: '5px',
          fontSize: '1.2em'
        }}
      >
        {store.lang['SUCCES_PAYMENT']}
      </div>
      <div style={divStyles}>{store.lang['SUCCES_PAYMENT_INST_0']}</div>

      <div
        style={{
          ...divStyles,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px'
        }}
      >
        {products &&
          products.map(pr => (
            <div style={{ margin: '5px' }}>
              <Button
                disabled={pr.nDownloads <= 0}
                key={pr.id}
                style={{
                  background: pr.nDownloads <= 0 ? '#cac3c3' : 'rgb(173,29,143',
                  color: 'white',
                  width: '90%'
                }}
                startIcon={<GetAppIcon />}
                onClick={e =>
                  onClick({
                    action: 'DOWNLOAD_FILE',
                    payload: { id: pr.id, filePath: pr.filePath }
                  })
                }
              >
                <strong>
                  {pr.title +
                    ' ' +
                    (pr.nDownloads <= 0
                      ? store.lang['EXCEED_2_DOWNLOADS']
                      : '')}
                </strong>
              </Button>
            </div>
          ))}
      </div>
      <br />
      <br />
      <div
        style={{
          ...divStyles,
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px'
        }}
      >
        {interpolateString(store.lang['SUCCES_PAYMENT_INST_1'], [email])}
      </div>

      <div
        style={{
          ...divStyles,
          borderBottomLeftRadius: '5px',
          borderBottomRightRadius: '5px'
        }}
      >
        {interpolateString(store.lang['SUCCES_PAYMENT_INST_2'], [email])}
      </div>
    </div>
  )
}

export default MinibooksPaymentsView
