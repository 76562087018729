import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const defaultState = {
  count: 0,
  page: 0,
  rowsPerPage: 0,
  filteredDataIn: []
}

function usePagination ({ dataIn = [], rowsPerPageOptions = [] }) {
  const [state, setState] = useState(defaultState)

  function setRowsPerPage (_numRows) {
    if (_numRows === -1)
      setState(prevState => ({ ...prevState, rowsPerPage: -1 }))
    else setState(prevState => ({ ...prevState, rowsPerPage: _numRows }))
  } //setRowsPerPage

  function setPage (_page) {
    setState(prevState => ({ ...prevState, page: _page }))
  } //page

  function startPage () {
    setState(prevState => ({ ...prevState, page: 0 }))
  } //startPage

  function endPage () {
    setState(prevState => ({ ...prevState, page: 2 }))
  } //endPage

  useEffect(() => {
    setState(prevState => {
      return {
        ...prevState,
        count: dataIn.length,
        rowsPerPage: rowsPerPageOptions[0],
        page: 0,
        filteredDataIn: dataIn.filter((data, i) => {
          if (i < rowsPerPageOptions[0]) return true
          else return false
        })
      }
    })
  }, [])

  useEffect(() => {
    setState(prevState => {
      return {
        ...prevState,
        count: dataIn.length,
        filteredDataIn: dataIn.filter((data, i) => {
          if (prevState.rowsPerPage === -1) return true
          if (
            i >= prevState.rowsPerPage * prevState.page &&
            i < prevState.rowsPerPage * (prevState.page + 1)
          ) {
            return true
          } else {
            return false
          }
        })
      }
    })
  }, [state.page, state.rowsPerPage, dataIn])

  return [
    state,
    { setRowsPerPage, setPage, startPage, endPage },
    { rowsPerPageOptions }
  ]
}

export default usePagination
