import React from 'react'

function Wrapper ({
  visible = true,
  compNotVisible,
  errorMsg = '',
  errorMsgStyle,
  children
}) {
  if (!visible && compNotVisible) {
    return compNotVisible
  }
  if (!visible && !compNotVisible) {
    return null
  }
  if (errorMsg) {
    return (
      <>
        {children}
        <div style={errorMsgStyle ? errorMsgStyle : { color: 'red' }}>
          {errorMsg}
        </div>
      </>
    )
  }
  return children
} //Wrapper

export default Wrapper
