import React, { useState } from 'react'

export default function useProgress ({ defaultIsShow }) {
  const [isShow, setIsShow] = useState(defaultIsShow)

  function show () {
    setIsShow(true)
  }
  function hide () {
    setIsShow(false)
  }
  function toggle () {
    setIsShow(isShow => !isShow)
  }

  return [isShow, show, hide, toggle]
} //useProgress
