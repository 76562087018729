import React, { useEffect, useState } from 'react'

export default function useFeedback ({ defaultState }) {
  const [state, setState] = useState(defaultState)

  function handleClose () {
    setState({ ...state, visible: false })
  }
  function showFeedback (_state) {
    setState(_state)
  }
  function hideFeedback () {
    setState({ ...defaultState, visible: false })
  }
  function info (_message) {
    setState({
      ...state,
      severity: 'info',
      visible: true,
      primaryMessage: _message
    })
  }
  function error (_message) {
    setState({
      ...state,
      severity: 'error',
      visible: true,
      primaryMessage: _message
    })
  }
  function success (_message) {
    setState({
      ...state,
      severity: 'success',
      visible: true,
      primaryMessage: _message
    })
  }

  function handleError (_in) {
    /** _in:
    error,
    navigate,
    url,
  
    lang
     */

    if (_in.error && _in.error.code && _in.error.message) {
      // error en el servidor

      if (_in.error.code === 401) {
        _in.navigate(_in.url)
      }

      error(
        _in.lang[_in.error.code]
          ? _in.lang[_in.error.code]
          : _in.lang['UNEXPECTED_SERVER_ERROR']
      )
    } else {
      error(
        _in.lang[_in.message]
          ? _in.lang[_in.message]
          : _in.lang['UNEXPECTED_SERVER_ERROR']
      )
    }
  } // handleError

  return [
    state,
    handleClose,
    showFeedback,
    hideFeedback,
    { info, error, success },
    handleError
  ]
} //useFeedback
