import firebase, {
  auth,
  functions,
  firestore,
  storage
} from '../common/firebase';

//setTracking
export const setTracking = async (params, batch) => {
  
  try {
    params = { ...params, date: firebase.firestore.FieldValue.serverTimestamp() }
    if (!batch) {
      const trackRegisterRef = await firestore.collection('tracks').add(params);
      const trackRegister = await trackRegisterRef.get();
      const newTrack = {
        id: trackRegister.id,
        ...trackRegister.data(),
      };
      return newTrack;
    } else {
      let trackRegisterRef = firestore.collection('tracks').doc();
      let trackRegister = batch.set(trackRegisterRef, params);
      return trackRegister;
    }

  } catch (err) {
    throw err;
  }

}

//getDataTracking
export const getDataTracking = async () => {
  let trackRes = []
  const tracksSnapshot = await firestore.collection('tracks').orderBy('date','desc').get();
  tracksSnapshot.forEach(doc => {
    const id = doc.id;
    const data = doc.data();
    trackRes.push({id, ...data})
  });

  return trackRes;
}