import React, { useContext } from 'react'

import TextField from '@material-ui/core/TextField'

import SGrid from '../../../components/SGrid'
import Wrapper from '../../../components/Wrapper'

import { StoreContext } from '../../../contexts/StoreProvider'

function CheckOutClient ({
  name = '',
  phone = '',
  email = '',
  error = { name: '', phone: '', email: '' },
  onChange = () => {}
}) {
  const { store, dispatchStore, actions, func } = useContext(StoreContext)

  return (
    <div>
      <SGrid xs={12} xm={4} spacing={2}>
        <Wrapper
          visible={true}
          compNotVisible={undefined}
          errorMsg={store.lang[error.name]}
        >
          <TextField
            onChange={e =>
              onChange({
                action: 'ONCHANGE',
                name: 'name',
                value: e.target.value
              })
            }
            value={name}
            label={store.lang['NAME'] + '(*)'}
            style={{ ...store.styles.TextField(), width: '100%' }}
          />
        </Wrapper>
        <Wrapper
          visible={true}
          compNotVisible={undefined}
          errorMsg={store.lang[error.email]}
        >
          <TextField
            onChange={e =>
              onChange({
                action: 'ONCHANGE',
                name: 'email',
                value: e.target.value
              })
            }
            value={email}
            label={store.lang['EMAIL'] + '(*)'}
            style={{ ...store.styles.TextField(), width: '100%' }}
          />
        </Wrapper>
        <Wrapper
          visible={true}
          compNotVisible={undefined}
          errorMsg={store.lang[error.phone]}
        >
          <TextField
            onChange={e =>
              onChange({
                action: 'ONCHANGE',
                name: 'phone',
                value: e.target.value
              })
            }
            value={phone}
            label={store.lang['OPTIONAL_PHONE']}
            style={{ ...store.styles.TextField(), width: '100%' }}
          />
        </Wrapper>
      </SGrid>
    </div>
  )
}

export default CheckOutClient
