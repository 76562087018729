import React, { useContext } from 'react'

function CookiesPolicy () {
  return (
    <div style={{ padding: '20px', color: 'rgb(179,43,129)' }}>
      <h2>POLÍTICA DE COOKIES</h2>
      <p>
        En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley 34/2002,
        de 11 de julio, de Servicios de la Sociedad de la Información y de
        Comercio Electrónico, esta página web le informa, en esta sección, sobre
        la política de recogida y tratamiento de cookies.
      </p>
      <h3>¿Qué son las cookies?</h3>
      <p>
        Una cookie es un fichero que se descarga en su ordenador al acceder a
        determinadas páginas web. Las cookies permiten a una página web, entre
        otras cosas, almacenar y recuperar información sobre los hábitos de
        navegación de un usuario o de su equipo y, dependiendo de la información
        que contengan y de la forma en que utilice su equipo, pueden utilizarse
        para reconocer al usuario.
      </p>
      <h3>Tipos de cookies que usa este sitio web</h3>
      <p>
        Las cookies usadas en este sitio web no se utilizan con fines
        publicitarios, sino con finalidades técnicas, de análisis,
        personalización y de sesión de usuario. Se utilizan tanto cookies
        propias (enviadas desde el propio editor del dominio) como de terceros
        (enviadas por otra entidad diferente al editor del dominio). Se utilizan
        tanto cookies de sesión (almacenan datos mientras dura la sesión del
        usuario) como persistentes (el periodo de duración puede ir de unos
        minutos a varios días). Se utilizan cookies de análisis, que nos
        permiten a nosotros o a terceros cuantificar el número de usuarios y así
        realizar la medición y análisis estadístico de las visitas.
      </p>
      <h3>Revocación y eliminación de cookies</h3>
      <p>
        Puede usted permitir, bloquear o eliminar las cookies instaladas en su
        equipo mediante la configuración de las opciones del navegador instalado
        en su ordenador, en caso que no permita la instalación de cookies en su
        navegador es posible que no pueda acceder a alguna de las secciones de
        nuestra web. - Internet Explorer: Haga clic en el botón Herramientas y
        después en Opciones de Internet. Haga clic en la pestaña Privacidad y
        después en Sitios. En el cuadro Dirección del sitio web, escriba la
        dirección completa (URL) del sitio Web cuya configuración de privacidad
        quiera personalizar. Después haga clic en Bloquear o Permitir. Para más
        información sobre Internet Explorer pulse aquí:
        http://windows.microsoft.com/es-419/windows7/how-to-manage-cookies-in-internet-explorer-9.
        - Safari: Acceda a menú 'Safari'. Entre en el apartado 'Preferencias'.
        Haga click sobre la pestaña Seguridad y podrá ajustar las Cookies. Para
        más información sobre Safari pulse aquí:
        http://support.apple.com/kb/PH5042 . - Chrome: Haga clic en el menú de
        Chrome y seleccione Configuración. Haga clic en Mostrar opciones
        avanzadas. En la sección 'Privacidad', haga clic en el botón
        Configuración de contenido. En la sección 'Cookies', se pueden eliminar,
        bloquear o permitir las cookies para sitios específicos. Para más
        información sobre Chrome pulse aquí:
        http://support.google.com/chrome/answer/95647?hl=es. - Firefox: Haga
        clic en el botón de Herramientas y luego en Opciones. Haga clic en la
        pestaña Privacidad. En la sección Historial, seleccione 'Usar una
        configuración personalizada para el historial'. Marque la casilla para
        aceptar las cookies y haga clic en Excepciones para seleccionar los
        sitios web que (siempre o nunca) autorice a instalar cookies en su
        dispositivo. Para más información sobre Mozilla Firefox pulse aquí:
        http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we.
        - Opera: Opciones - Avanzado - Cookies. Las opciones de cookies
        controlan el modo en que Opera los maneja y por lo tanto su aceptación o
        rechazo. Para más información sobre Ópera pulse aquí:
        http://help.opera.com/Linux/10.60/es-ES/cookies.html.{' '}
      </p>
    </div>
  )
} //Legal

export default CookiesPolicy
