import React, { useContext, useEffect } from 'react'

import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import HomeIcon from '@material-ui/icons/Home'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { navigate } from '@reach/router'
import { StoreContext } from '../../contexts/StoreProvider'
import { Button } from '@material-ui/core';
import useTracker, { CLICK_SHOPPING_CART } from '../../hooks/useTracker'

const useStyles = makeStyles({
  backofficetitle: {
    '&:hover': {
      //background: 'grey'
      cursor: 'pointer'
    }
  }
})

function MinibooksBackoffice(props) {
  const { store, dispatchStore, actions, func } = useContext(StoreContext)
  const [handleTracker] = useTracker(store.activeTracker);

  useEffect(() => {
    func.getItemsFromStore()
  }, [])

  const styles = useStyles()
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          //textAlign: 'right',
          //display: 'inline-block',
          //right: '25px',
          //top: '7px',
          background: 'black',
          color: 'white',
          right: '10px',
          top: '70px',
          zIndex: '30000'
        }}
      >
        <IconButton
          onClick={e => {
            handleTracker(CLICK_SHOPPING_CART, {shoppingCart: store.shoppingCart ? store.shoppingCart : []});
            navigate('/minibooks/checkout');
          }}
          color='inherit'
        >
          <Badge badgeContent={store.shoppingCart.length} color='secondary'>
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      </div>

      <AppBar
        position='fixed'
        style={{
          textAlign: 'center',
          background: 'rgb(173,29,143)'
        }}
      >
        <div className={styles.backofficetitle}>
          <Button
            startIcon={<HomeIcon style={{ color: 'white', fontSize: '2em' }} />}
            onClick={e => navigate('/minibooks/')}
          >
            <div
              style={{
                display: 'inline-block',
                padding: '15px',
                paddingBottom: '2px'
              }}
            >
              <div
                style={{
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  color: 'white'
                }}
              >
                MINIBOOKS DE ESTETICA40
              </div>
            </div>
          </Button>
          <div style={{ paddingBottom: '7px' }}>
            contacto: abril@estetica40.com
          </div>
          <div style={{ display: 'none' }}>
            <span onClick={e => alert('hola')}>{store.lang['LOGIN']}</span> |{' '}
            <span>{store.lang['REGISTER']}</span> |{' '}
            <span>{store.lang['EXIT']}</span>
          </div>
        </div>
      </AppBar>
      <div>
        <div style={{ marginBottom: '25px' }}></div>
        {props.children}
      </div>
      <br />

      <div
        style={{
          textAlign: 'center',
          paddingBottom: '15px',
          color: store.styles.primaryColor
        }}
      >
        {' '}
        <a href={'/minibooks/legal'}>Aviso Legal</a> |{' '}
        <a href={'/minibooks/privacy'}>Política de privacidad </a>|
        <a href={'/minibooks/cookies'}>Política de cookies</a>
      </div>
    </div>
  )
}

export default MinibooksBackoffice
