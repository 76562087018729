import React, { useEffect } from 'react'

import { dateUTCPlus1 } from '../common/Utils'

import firebase, {
  auth,
  functions,
  firestore,
  storage
} from '../common/firebase'
import { getAccessData } from '../common/Config'
import { useState } from 'react'

import useCrud from '../hooks/UseCrud'

function Backup () {
  let id = '693JSytiRuOSrkIhVebc'
  const [crudSensors] = useCrud({
    arrCol: ['/sensors/', id]
  })

  useEffect(() => {
    return
    console.log('BACKUP_UseEffect')
    let bobSensorStr =
      'tipo;learnig_percent;vibration_level;peak_frecuency;temperature;learnig_type;fft1;fft2;fft3;fft4;fft5;fft6;fft7;fft8;fft9;fft10;fft11;fft12;fft13;fft14;fft15;fft16;fft17;fft18;fft19;fft20;fft21;fft22;fft23;fft24;fft25;fft26;fft27;fft28;fft29;fft30;fft31;fft32;anomaly_level;operating_time;anomaly_time;alarm_number;reportid;ano_lev_time_10_20;ano_lev_time_20_40;ano_lev_time_40_60;ano_lev_time_60_80;ano_lev_time_80_100;batery;anomaly_forecast_24h_20;anomaly_forecast_24h_50;anomaly_forecast_24h_80;anomaly_forecast_30d_20;anomaly_forecast_30d_50;anomaly_forecast_30d_80;anomaly_forecast_6m_20;anomaly_forecast_6m_50;anomaly_forecast_6m_80;fft1a;fft2a;fft3a;fft4a;fft5a;fft6a;fft7a;fft8a;fft9a;fft10a;fft11a;fft12a;fft13a;fft14a;fft15a;fft16a;fft17a;fft18a;fft19a;fft20a;fft21a;fft22a;fft23a;fft24a;fft25a;fft26a;fft27a;fft28a;fft29a;fft30a;fft31a;fft32a;state'

    let te8911Str =
      'temperatura;bateria;total_energy;intregration_size;picos;pico_1_frec;pico_1_ampli;pico_1_ratio;pico_2_frec;pico_2_ampli;pico_2_ratio;pico_3_frec;pico_3_ampli;pico_3_ratio;pico_4_frec;pico_4_ampli;pico_4_ratio;pico_5_frec;pico_5_ampli;pico_5_ratio;pico_6_frec;pico_6_ampli;pico_6_ratio;pico_7_frec;pico_7_ampli;pico_7_ratio;pico_8_frec;pico_8_ampli;pico_8_ratio'

    //Proccesing Yokowaga
    /*
    let yokowagaStr = 'tipo;estado;pv_aceleracion;pv_velocidad;pv_temperatura'
    let yokowagaArr = yokowagaStr.split(';')
    console.log('yokoawagaArr', yokowagaArr)
    let columnsArr = []
    yokowagaArr.forEach(data => {
      columnsArr.push({ name: data, alias: data })
    })
    console.log('columnsArr', columnsArr)
    */
    let yokowagaArr = bobSensorStr.split(';')
    console.log('yokoawagaArr', yokowagaArr)
    let columnsArr = []
    yokowagaArr.forEach(data => {
      columnsArr.push({ name: data, alias: data })
    })
    console.log('columnsArr', columnsArr)

    let batch = firestore.batch()

    crudSensors.update({
      batch,
      payload: { columns: columnsArr }
    })

    batch
      .commit()
      .then(res => {
        console.log('todoOk')
      })
      .catch(err => {
        console.log('ERROR', err)
      })
  }, [])
  return <div>BACKUP</div>
}
//BobSensor: tipo;learnig_percent;vibration_level;peak_frecuency;temperature;learnig_type;fft1;fft2;fft3;fft4;fft5;fft6;fft7;fft8;fft9;fft10;fft11;fft12;fft13;fft14;fft15;fft16;fft17;fft18;fft19;fft20;fft21;fft22;fft23;fft24;fft25;fft26;fft27;fft28;fft29;fft30;fft31;fft32;anomaly_level;operating_time;anomaly_time;alarm_number;reportid;ano_lev_time_10_20;ano_lev_time_20_40;ano_lev_time_40_60;ano_lev_time_60_80;ano_lev_time_80_100;batery;anomaly_forecast_24h_20;anomaly_forecast_24h_50;anomaly_forecast_24h_80;anomaly_forecast_30d_20;anomaly_forecast_30d_50;anomaly_forecast_30d_80;anomaly_forecast_6m_20;anomaly_forecast_6m_50;anomaly_forecast_6m_80;fft1a;fft2a;fft3a;fft4a;fft5a;fft6a;fft7a;fft8a;fft9a;fft10a;fft11a;fft12a;fft13a;fft14a;fft15a;fft16a;fft17a;fft18a;fft19a;fft20a;fft21a;fft22a;fft23a;fft24a;fft25a;fft26a;fft27a;fft28a;fft29a;fft30a;fft31a;fft32a;state;

export default Backup
