import React from 'react'

import { makeStyles } from '@material-ui/styles'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

/**
 * visible: true | false
 *
 * componentType= div (by default) || alert || dialog || snackBar
 * primaryMessage:
 * secondaryMessage

 * actions: <Button>hola</Button>
 *
 * classes={error | info | warning | success}
 * 
 * Si snackBar:
 * duration: Duración de la barra
 * onClose
 *
 *
 */

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import clsx from 'clsx'

const useStyles = makeStyles({
  error: {
    backgroundColor: 'rgb(252, 239, 238)',
    color: 'red',
    border: '1px solid red',
    borderRadius: '5px',
    padding: '10px'
  },
  info: {
    backgroundColor: 'rgb(205, 233, 247)',
    color: 'rgb(58,119,173)',
    border: '1px solid rgb(58,119,173)',
    borderRadius: '5px',
    padding: '10px'
  },
  success: {
    backgroundColor: 'rgb(220, 243, 214)',
    color: 'rgb(94,118,86)',
    border: '1px solid rgb(94,118,86)',
    borderRadius: '5px',
    padding: '10px'
  },
  warning: {
    backgroundColor: 'rgb(248, 244, 196)',
    color: 'rgb(148, 109, 40)',
    border: '1px solid rgb(148, 109, 40)',
    borderRadius: '5px',
    padding: '10px'
  }
})

const Feedback = ({
  visible = false,
  duration = 3000,
  onClose = () => {},
  primaryMessage = '',
  severity = 'info'
}) => {
  let classes = useStyles()

  return (
    <Snackbar
      open={visible}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert elevation={6} variant='filled' severity={severity}>
        {primaryMessage}
      </MuiAlert>
    </Snackbar>
  )
}

export default Feedback
