import React, { useContext, useEffect, useState } from 'react'

import firebase, { auth, firestore } from '../common/firebase'

import { Router, navigate } from '@reach/router'

import { StoreContext } from '../contexts/StoreProvider'

import SensorListView from './sensor/SensorListView'

import useCrud from '../hooks/UseCrud'
import useCheck from '../hooks/UseCheck'
import useFeedback from '../hooks/UseFeedBack'
import useProgress from '../hooks/UseProgress'
import SProgress from '../components/SProgress'
import LayoutBOContent from '../components/LayoutBOContent'
import Feedback from '../components/Feedback'

import SGrid from '../components/SGrid'
import Wrapper from '../components/Wrapper'
import { Paper } from '@material-ui/core'

const defaultState = {
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

function Main (props) {
  const { store, dispatchStore } = useContext(StoreContext)

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)

  const [sensors, setSensors] = useState([])

  const [checkIsState, handleCheckState] = useCheck({
    conditions: [
      {
        name: state.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: state.capacity,
        errorName: 'capacity',
        check: ['required', 'number', ['>', 0, 'field_bigger_0']]
      },
      {
        name: state.userappMail,
        errorName: 'userappMail',
        check: ['required', ['function', () => {}, state.param]]
      }
    ],
    setErrorState: setStateError,
    errorState: {},
    defaultErrorState: defaultStateError
  })

  const [crudSensor] = useCrud({
    arrCol: ['/sensors/']
  })

  useEffect(() => {
    let sensorsRef = crudSensor.findDocRef({})

    sensorsRef
      .get()
      .then(res => {
        let sensorRes = crudSensor.getResult({ data: res })

        setSensors(sensorRes)
      })
      .catch(err => {
        console.log('err', err)
      })
  }, [])

  async function handleClick () {
    //create/upate resource
    let check = handleCheckState()
    if (check) {
      feedback.error(store.lang['USER_DATA_ERROR'])
      return
    }

    try {
      showProgress()
      let batch = firestore.batch()

      crudSensor.create({
        batch,
        payload: {}
      })

      batch
        .commit()
        .then(res => {
          feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (err) {
      handleGlobalError({
        error: err,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    } finally {
      hideProgress()
    }
  } //handleClickCreateResource

  return (
    <div>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      {/*<DialogDeleteConfirm
        open={dialogConfirm.showModal}
        code={dialogConfirm.code}
        onClick={({ type, code, payload }) => {
          if (type === 'CANCEL') {
          }
          if (type === 'OK') {
            switch (code) {
              case 'deleteGuest':
                handleDeleteGuest(dialogConfirm.payload)
                break
              case 'deleteResource':
                handleDeleteResource(dialogConfirm.payload)
                break
            }
          }
          setDialogConfirm(defaultDialogConfirm)
        }}
      />*/}

      <LayoutBOContent>
        <SGrid xs={12} sm={12}>
          <Wrapper
            visible={true}
            //compNotVisible={undefined}
            //errorMsg={props.error.field}
          >
            <SensorListView
              title={store.lang['SENSORS']}
              sensors={sensors}
              onClick={({ action, id }) => {
                if (action == 'VIEW') {
                  navigate('/' + id)
                }
              }}
            />
          </Wrapper>
        </SGrid>
      </LayoutBOContent>
    </div>
  )
}

export default Main
