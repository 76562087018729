import React from 'react';

import Button from '@material-ui/core/Button';

import {exportExcel} from '../../../common/Utils'
import {getDataTracking} from '../../../cruds/crudTracker';
import {CLICK_BUY_MINIBOOKS,
        CLICK_BUY_MINIBOOKS_DETAILS,
        CLICK_SHOPPING_CART,
        ERROR_ONCLICK_PAY,
        PAYMENT_SUCCESS } from '../../../hooks/useTracker';

const TrackingReport = () => {

 const downloadTrackingData = async () =>{
    let data = await getDataTracking();

    let dataToExport = data.map(dataItem => {
     return {
      id: dataItem.id,
      date: new Date(dataItem.date.seconds *1000),
      message: dataItem.message,
      user: dataItem.user,
      data: extraInfoTrackingMainFields(dataItem.message, dataItem.extraInfo),
      extraInfo: JSON.stringify(dataItem.extraInfo)
     }

    });
   
    exportExcel(dataToExport);
 }

 return (<div style = {{margin: '20px',marginTop: '100px'}}>
 <Button style={{border: '2px solid rgb(173,29,143)', color: 'rgb(173,29,143)' }} onClick = {e => downloadTrackingData()}>
   Download Tracking Report
  </Button>
 </div>)
}
 
export default TrackingReport;

//extraInfoTrackingMainFields
const extraInfoTrackingMainFields = (message, extraInfo) =>{
  try{
    let dataToReturnStr= '';
    switch(message){
     case CLICK_BUY_MINIBOOKS:
      dataToReturnStr = extraInfo.book.title;
      break;
     case CLICK_BUY_MINIBOOKS_DETAILS:
      dataToReturnStr = extraInfo.book.title;
      break;
     case CLICK_SHOPPING_CART:
      dataToReturnStr = extraInfo.shoppingCart.map(extraInfoItem => {
       return extraInfoItem.title
      }).join(' || ');
      break;
     case ERROR_ONCLICK_PAY:
      dataToReturnStr = extraInfo.message ? extraInfo.message : extraInfo.error.message;
      break;
     case PAYMENT_SUCCESS:
      dataToReturnStr = extraInfo.shoppingCart.map(extraInfoItem => {
       return extraInfoItem.title
      }).join(' || ');
      break;
     default:
    }
    return dataToReturnStr;
 }catch(err){
  return '';
 }
}