import React from 'react'

import Paper from '@material-ui/core/Paper'
import StylesContext from '../contexts/StylesContext'
import { withTheme } from '@material-ui/core'

export default function LayoutBOContent ({
  title = '',
  headerButtom = null,
  children
}) {
  //return <Paper>Hola</Paper>
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '15px',
        paddingTop: '70px'
        //paddingLeft: '40px',
      }}
    >
      {title !== '' ? (
        <div>
          <span
            style={{
              position: 'relative',
              textAlign: 'left',
              fontSize: '1.3em'
              //marginBottom: '10px'
            }}
          >
            {title}
          </span>
          {headerButtom && (
            <>
              <span style={{ marginRight: '25px' }} />
              <span
                style={{
                  position: 'relative',
                  textAlign: 'center',
                  marginBottom: '10px'
                }}
              >
                {headerButtom}
              </span>
            </>
          )}
        </div>
      ) : null}

      {children}
    </div>
  )
} //LayoutBOContent
