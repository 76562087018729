import React, { useEffect } from 'react'
/**
 * type : Tipo del campo a chequear
 *
 * error: true |false
 * errorMessage: Mensaje de error del campo
 *
 * enabled: Si esta habilitado o no
 * compDisabled: Componente a cargar si enabled=false
 * propsDisabled: Propiedades si enabled=false
 *
 * visible: si el elemento es o no visible
 */

import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import { isObjEmpty } from '../common/Utils'

let useStyles = makeStyles({
  root: {
    border: '1px solid red',
    padding: '5px',
    borderRadius: '5px'
  },
  errorMessage: {
    color: 'red',
    fontSize: '0.75em',
    textAlign: 'left'
  }
})

const CheckElement = props => {
  let classes = useStyles()

  useEffect(() => {})

  if (!props.visible) return null

  if (!props.enabled) {
    if (props.compDisabled) {
      return props.compDisabled
    } else if (!isObjEmpty(props.propsDisabled)) {
      return (
        <React.Fragment>
          {React.Children.map(props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, props.propsDisabled)
            } else {
              return child
            }
          })}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {React.Children.map(props.children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { disabled: 'true' })
            } else {
              return child
            }
          })}
        </React.Fragment>
      )
    }
  }

  return (
    <div className={clsx(props.error && classes.root)}>
      {props.children}
      <div className={classes.errorMessage}>
        {props.errorMessage !== '' && props.errorMessage}
      </div>
    </div>
  )
}

CheckElement.defaultProps = {
  type: 'TextField',

  error: false,
  errorMessage: '',

  visible: true,
  compDisabled: undefined,
  enabled: true,
  propsDisabled: {}
}

export default CheckElement
