import React, { useContext } from 'react'
import Paper from '@material-ui/core/Paper'

import SGrid from '../../components/SGrid'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { StoreContext } from '../../contexts/StoreProvider'

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  imgclass: {
    '&:hover': {
      //background: 'grey'
      cursor: 'pointer'
    }
  }
})

function Book({
  title = '',
  description = '',
  price = '',
  imgSrc = '',
  onClick = () => { }
}) {
  const { store, dispatchStore } = useContext(StoreContext)
  const classes = useStyles()

  return (
    <div
      style={{
        backgroundColor: 'white',
        textAlign: 'center',
        //border: '1px solid blue',
        padding: '7px',
        maxWidth: '220px',
        //boxShadow:
        // '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',

        boxShadow:
          'rgb(0 0 0 / 30%) 0px 2px 1px -1px, rgb(0 0 0 / 20%) 0px 1px 1px 0px, rgb(0 0 0 / 20%) 0px 1px 3px 0px'
      }}
    >
      <img
        onClick={e => onClick({ action: 'VIEW_DETAILS' })}
        className={classes.imgclass}
        style={{}}
        src={imgSrc}
        alt='Girl in a jacket'
        width='200px'
        height='auto'
      />
      <div
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          position: 'relative',
          fontSize: '1.07em',
          maxWidth: '200px',
          minHeight: '44px'
        }}
      >
        {title}
      </div>
      <br />
      <div>
        {description.substring(0, 60)}
        <span
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={e => onClick({ action: 'VIEW_DETAILS' })}
        >
          ...{store.lang['SEE_MORE']}
        </span>
      </div>
      <br />
      <div>
        <SGrid xs={6} sm={6}>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.1em',
              textAlign: 'left',
              paddingLeft: '15px'
            }}
          >
            {price} €
          </div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 'em',
              textAlign: 'rigth'
            }}
          >
            <Button
              onClick={e => onClick({ action: 'BUY' })}
              style={{ background: 'rgb(173,29,143', color: 'white' }}
            >
              {store.lang['BUY']}
            </Button>
          </div>
        </SGrid>
      </div>
    </div>
  )

  /*

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image='https://www.estetica40.com/wp-content/uploads/edd/portada-acne-2.png'
          title='Contemplative Reptile'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            Lizard
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size='small' color='primary'>
          Share
        </Button>
        <Button size='small' color='primary'>
          Learn More
        </Button>
      </CardActions>
    </Card>
  )*/
}

export default Book
