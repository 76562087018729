import React from 'react'
import firebase, {
  auth,
  functions,
  firestore,
  storage
} from '../common/firebase'

export default function useCrud ({ arrCol }) {
  /**
   * arrCol: Array of String that determines collection
   */

  function getResult ({ data }) {
    let dataRes = []

    data.forEach(doc => {
      dataRes.push({ id: doc.id, ...doc.data() })
    })

    return dataRes
  } //getResult

  function find ({ batch, payload, _col }) {
    try {
      let colAux = arrCol.join('')
      if (_col) colAux = _col

      let documentRef = firestore.collection(colAux)
      return documentRef
    } catch (err) {
      throw err
    }
  }

  function findDocRef ({ batch, payload, _arrCol }) {
    try {
      let arrColAux = arrCol.filter(arr => arr !== undefined && arr !== null)
      let isCollection = true //if false is document
      let colAux = arrCol.join('')
      if (arrColAux && arrColAux.length != 0 && arrColAux.length % 2 === 0)
        isCollection = false
      if (_arrCol && _arrCol.length != 0) {
        let _arrColAux = _arrCol.filter(
          arr => arr !== undefined && arr !== null
        )
        colAux = _arrCol.join('')
        if (_arrColAux && _arrColAux.length != 0 && _arrColAux.length % 2 === 0)
          isCollection = false
      }

      if (isCollection) {
        let documentRef = firestore.collection(colAux)
        return documentRef
      } else {
        let documentDoc = firestore.doc(colAux)
        return documentDoc
      }
    } catch (err) {
      throw err
    }
  } //findDocRef

  async function create ({ batch, payload, _col }) {
    try {
      let documentRef = null
      let colAux = arrCol.join('')
      for (let i = 0; i < arrCol.length; i++) {
        if (!arrCol[i] || arrCol[i] === '') {
          colAux = _col
        }
      }
      documentRef = firestore.collection(colAux).doc()

      let documentCreated = await batch.set(documentRef, {
        ...payload,
        createdAt: payload.createdAt
          ? payload.createdAt
          : firebase.firestore.FieldValue.serverTimestamp(),
        deletedAt: payload.deletedAt
          ? payload.deletedAt
          : new Date('2099-07-01T00:00')
      })

      return documentRef
    } catch (err) {
      throw err
    }
  } //create

  async function update ({ batch, id, payload, _col }) {
    try {
      let documentRef = null

      //let colAux = arrCol.join('')
      let colAux = ''
      if (!_col) {
        for (let i = 0; i < arrCol.length; i++) {
          if (arrCol[i]) {
            colAux += arrCol[i]
          }
        }
        colAux += id
      } else {
        colAux = _col + id
      }

      documentRef = firestore.doc(colAux)

      await batch.update(documentRef, {
        ...payload
      })

      return documentRef
    } catch (err) {
      throw err
    }
  } //create

  async function remove ({ batch, id, payload, _col }) {
    try {
      let documentRef = null

      let colAux = arrCol.join('')
      for (let i = 0; i < arrCol.length; i++) {
        if (!arrCol[i] || arrCol[i] === '') {
          colAux = _col
        }
      }

      documentRef = firestore.doc(colAux + '/' + id)

      await batch.delete(documentRef)

      return documentRef
    } catch (err) {
      throw err
    }
  } //create

  return [{ create, find, findDocRef, update, remove, getResult }]
} //useCrud
