import React, { useContext, useEffect, useState } from 'react'

import firebase, { auth, firestore } from '../common/firebase'
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles'

import DOMPurify from 'dompurify'

import { Router, navigate } from '@reach/router'

import { StoreContext } from '../contexts/StoreProvider'

import useCrud from '../hooks/UseCrud'
import useCheck from '../hooks/UseCheck'
import useFeedback from '../hooks/UseFeedBack'
import useProgress from '../hooks/UseProgress'
import SProgress from '../components/SProgress'
import LayoutBOContent from '../components/LayoutBOContent'
import Feedback from '../components/Feedback'

import SGrid from '../components/SGrid'
import Wrapper from '../components/Wrapper'
import useTracker, { CLICK_BUY_MINIBOOKS_DETAILS } from '../hooks/useTracker'


const useStyles = makeStyles({
  arrowBack: {
    '&:hover': {
      cursor: 'pointer',
    },
    color: 'rgb(173,29,143)'
  }
})


const defaultState = {
  id: '',
  title: '',
  imgSrc: '',
  content: '',
  price: '',
  filePath: ''
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

function MinibooksDetails({ slug }) {
  const { store, dispatchStore, actions, func } = useContext(StoreContext);
  let styles = useStyles();
  const [handleTracker] = useTracker(store.activeTracker);

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)

  const [checkIsState, handleCheckState] = useCheck({
    conditions: [
      {
        name: state.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: state.capacity,
        errorName: 'capacity',
        check: ['required', 'number', ['>', 0, 'field_bigger_0']]
      },
      {
        name: state.userappMail,
        errorName: 'userappMail',
        check: ['required', ['function', () => { }, state.param]]
      }
    ],
    setErrorState: setStateError,
    errorState: {},
    defaultErrorState: defaultStateError
  })

  const [crudMinibooks] = useCrud({
    arrCol: ['/minibooks/']
  })

  async function handleClick() {
    //create/upate resource
    let check = handleCheckState()
    if (check) {
      feedback.error(store.lang['USER_DATA_ERROR'])
      return
    }

    try {
      showProgress()
      let batch = firestore.batch()

      crudMinibooks.create({
        batch,
        payload: {}
      })

      batch
        .commit()
        .then(res => {
          feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (err) {
      handleGlobalError({
        error: err,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    } finally {
      hideProgress()
    }
  } //handleClickCreateResource

  async function handleClickBook(_in) {
    await handleTracker(CLICK_BUY_MINIBOOKS_DETAILS,{book: state})
    let res = func.addItemToCart(state)
    if (res === 'already_added') {
      feedbackFunc.info(store.lang['ALREADY_ADDED'])
    }
    if (res === 'done') {
      feedbackFunc.info(store.lang['ADDED_OK_TO_CART'])
    }
  } //handleClickBook

  useEffect(() => {
    const getMinibook = async () => {
      let minibookRef = crudMinibooks.findDocRef({})
      let minibookArr = await minibookRef.where('slug', '==', slug).get()
      let minibook = crudMinibooks.getResult({ data: minibookArr })
      if (minibook.length > 0) {
        setState({
          id: minibook[0].id,
          title: minibook[0].title,
          imgSrc: minibook[0].imgSrc,
          content: minibook[0].content,
          price: minibook[0].price,
          filePath: minibook[0].filePath,
          filePathSample: minibook[0].filePathSample ? minibook[0].filePathSample : null
        })
      }
    } //getMinibook

    getMinibook()
  }, [])

  if (state.id === '') {
    return (
      <div>
        <h1>{store.lang['PAGE_NOT_FOUND']}</h1>
      </div>
    )
  }

  return (
    <div>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      <LayoutBOContent>

        <div style={{ padding: '40px' }}>
          <SGrid xs={12} sm={12}>
            <Wrapper
              visible={true}
              compNotVisible={undefined}
            //errorMsg={props.error.field}
            >
              <ArrowBackIcon fontSize="large" className={styles.arrowBack}
                onClick={e => navigate('/minibooks')}
              />
              <div
                style={{
                  color: 'rgb(173,49,143)',
                  fontWeight: 'bold',
                  fontSize: '1.5em',
                  textAlign: 'center',
                  marginBottom: '15px'
                }}
              >
                {state.title}
              </div>
              <div style={{ textAlign: 'center' }}>
                <img src={state.imgSrc} width='200px' height='auto' />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(state.content)
                }}
              />

              <br />
              <div style={{ textAlign: 'center' }}>
                {state.filePathSample && <Button
                  size='large'
                  style={{
                    background: 'rgb(227 136 208)',
                    color: 'White',
                    width: '70%',
                    marginBottom: '5px'
                  }}
                  onClick={e => window.open(state.filePathSample)}
                >
                  <strong>{store.lang['DOWNLOAD_SAMPLE_FREE']}</strong>
                </Button>}
                <Button
                  size='large'
                  style={{
                    background: 'rgb(173,29,143)',
                    color: 'White',
                    width: '70%'
                  }}
                  onClick={e => handleClickBook()}
                >
                  <strong>{store.lang['BUY'] + ' ' + state.price + '€'}</strong>
                </Button>
              </div>
            </Wrapper>
          </SGrid>
        </div>
      </LayoutBOContent>
    </div>
  )
}

export default MinibooksDetails
