// let FIELD_REQUIRED = 'El campo $var1 es obligatorio'
import * as XLSX from 'xlsx'

export function showLog (str, obj) {
  if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev') {
    console.log(str)
    console.log(obj)
  }
} // showLog

export function exportExcel (data) {
  var ws = XLSX.utils.json_to_sheet(data)
  let wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Hoja1')
  XLSX.writeFile(wb, 'sheetjs.xlsx')
} // exportExcel

export function emailToId (_email) {
  let idRes = _email.split('.')
  idRes = idRes.join('')
  idRes = idRes.split('@')
  idRes = idRes.join('')

  return idRes
} //emailToId

export function checkForm (_arrField) {
  /**
   *
   * _arrField.name
   * _arrField.errorName : Field name of the field that isnt right
   * _arrField.check[i]=['requierd','mail',['>=',3,'message']]
   */
  let objRes = {
    error: false
  }
  for (let k = 0; k < _arrField.length; k++) {
    let _field = _arrField[k]
    let _arrFields = _field.check

    let next = true
    for (let i = 0; i < _arrFields.length && next; i++) {
      if (typeof _arrFields[i] === 'string') {
        switch (_arrFields[i]) {
          case 'required':
            if (!_field.name) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'required_field'
              }

              next = false
            }
            break

          case 'email':
            let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            /* eslint-enable no-useless-escape */
            if (!pattern.test(_field.name)) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'wrong_mail_format'
              }
              next = false
            }
            break
          case 'number':
            if (!_field.name || _field.name === '' || isNaN(_field.name)) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'notNumber_field'
              }

              next = false
            }
            break

          default:
        }
      } else if (Array.isArray(_arrFields[i])) {
        switch (_arrFields[i][0]) {
          case '=':
            //console.log('==', _field.name, _arrFields[i][1])
            if (!(_field.name === _arrFields[i][1])) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]:
                  _arrFields[i].length === 3
                    ? _arrFields[i][2]
                    : 'generic_error_field'
              }
              next = false
            }
            break

          case '>':
            if (!(parseFloat(_field.name) > _arrFields[i][1])) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]:
                  _arrFields[i].length === 3
                    ? _arrFields[i][2]
                    : 'generic_error_field'
              }
              next = false
            }

            break

          case 'function':
            /**
             * primer parametro del array:function
             * segundo parametro del array: funcion a ejecutar
             * tercer parámetro del array: parametros de la función a ejecutar
             * La función devuelve '' si no hay error y el mensaje de error si hay error
             */
            let functionReturnValue = _arrFields[i][1](_arrFields[i][2])

            if (functionReturnValue !== '') {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: functionReturnValue
              }
              next = false
            }
            break
        }
      }
    }
  }

  return objRes
} //checkForm

export function navigateToInternalLink (_internalLink) {
  let aElem = document.createElement('a')

  aElem.setAttribute('href', _internalLink)

  aElem.click()
  aElem.remove()
} //navigateToInternalLink

export function getStateChanges (_inStateChanged, _state, params) {
  /** Return object whith updated fields. These fields will be updated in DB.
   * _inStateChanged: Object with updated fields.
   * _state: Object with all fileds. Fields that changed and fileds that didn´t change.
   */

  let objRes = {}
  if (!params) {
    // return object uigiter compatible
    for (let key in _inStateChanged) {
      objRes = { ...objRes, [key]: _state[key] }
    }
    return objRes
  }
} // getStateChanges

export function updateObj (_objIn, strField, value) {
  return { ..._objIn, [strField]: value }
} // updateObj

export function getPaginationCount (nResults, resultsPerPage) {
  let pageCountAux = 1
  if (nResults && nResults !== 0) {
    pageCountAux = Math.ceil(
      parseInt(nResults, 10) / parseInt(resultsPerPage, 10)
    )
  } else {
    pageCountAux = 1
  }

  return pageCountAux
} // getPaginationCount

export function getPersistentData (_id, _storage) {
  if (!_storage || !_storage.getItem(_id)) return null
  return JSON.parse(_storage.getItem(_id))
} //
export function setPersistentData (_id, _storage, _data) {
  _storage.setItem(_id, JSON.stringify(_data))
} //

export function isObjEmpty (objIn) {
  return Object.keys(objIn).length === 0
} // isObjEmpty

export function interpolateString (_strIn, arrStrings) {
  for (let i = 0; i < arrStrings.length; i++) {
    _strIn = _strIn.replace('$var' + i, arrStrings[i])
  }
  return _strIn
} // interpolateString

export function dateToTextfieldPlus1 (_date) {
  let dateTime = dateUTCPlus1(_date)
  let date = dateTime.split(' ')[0]
  let dateSplit = date.split('/')
  let year = dateSplit[2]
  let month = dateSplit[1]
  let day = dateSplit[0]
  return year + '-' + month + '-' + day
} //dateToTextfield

export function dateUTCPlus1 (_date) {
  let dateRes = ''

  _date = Intl.DateTimeFormat('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: 'numeric'
  }).format(new Date(_date))
  dateRes = _date.replace('T', ' ')
  dateRes = dateRes.substring(0, 19)

  return dateRes
} // UTC+1 DATE

export function checkShowElementError (arrNames, arrNamesError) {
  /**
   * If some arrNames inside arrNamesError then true else false
   */
  for (let i = 0; i < arrNames.length; i++) {
    for (let j = 0; j < arrNamesError.length; j++) {
      if (arrNames[i] === arrNamesError[j]) return true
    }
    return false
  }
} // checkShowElementError

export function checkMail (_email) {
  /* eslint-disable no-useless-escape */
  let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  /* eslint-enable no-useless-escape */
  return pattern.test(_email) // returns a boolean
} // checkMail

export function checkRequired (params, arrVars, arrNames) {
  /**
   * params.requiredMessage: Mensaje a mostrar
   */
  if (arrVars.length <= 0) {
    return {
      error: false,
      message: ''
    }
  }
  for (let i = 0; i < arrVars.length; i++) {
    if (Array.isArray(arrVars[i]) && arrVars[i].length <= 0) {
      // check if array is empty
      return {
        error: true,
        message: interpolateString(params.requiredMessage, [arrNames[i]]),
        name: arrNames[i]
      }
    }
    if (
      arrVars[i] === '' ||
      !arrVars[i] ||
      arrVars[i] === null ||
      arrVars[i] === undefined
    ) {
      return {
        error: true,
        message: interpolateString(params.requiredMessage, [arrNames[i]]),
        name: arrNames[i]
      }
    }
  }
  return {
    error: false,
    message: ''
  }
} // checkRequired

export function dispatchError (_in) {
  /**
   * Procesa el error en el useReducer
   */
  if (_in.error && _in.error.code && _in.error.message) {
    // error en el servidor
    if (_in.error.code === 401) {
      _in.navigate(_in.url)
      return
    }
    let message = _in.lang[_in.error.code]
      ? _in.lang[_in.error.code]
      : _in.lang['UNEXPECTED_SERVER_ERROR']

    return { message }
  } else {
    let message = _in.lang[_in.message]
      ? _in.lang[_in.message]
      : _in.lang['UNEXPECTED_SERVER_ERROR']

    return { message }
  }
} // dispatchError

export function handleError (_in) {
  /** _in:
  error,
  navigate,
  url,

  lang
   */

  if (_in.error && _in.error.code && _in.error.message) {
    // error en el servidor

    if (_in.error.code === 401) {
      _in.navigate(_in.url)
    }

    _in.setErrorState({
      ..._in.errorState,
      message: _in.lang[_in.error.code]
        ? _in.lang[_in.error.code]
        : _in.lang['UNEXPECTED_SERVER_ERROR']
    })
  } else {
    _in.setErrorState({
      ..._in.errorState,
      message: _in.lang[_in.message]
        ? _in.lang[_in.message]
        : _in.lang['UNEXPECTED_SERVER_ERROR']
    })
  }
} // handleError

export function handleUserFeedback (_in) {
  /** _in:
  error,
  navigate,
  url,
  feedbackState,
  setFeedbackState,
  lang
   */

  if (_in.error && _in.error.response && _in.error.response.data) {
    // error en el servidor

    if (_in.error.response.data.statusCode === 401) {
      _in.navigate(_in.url)
    }

    _in.setFeedbackState({
      ..._in.feedbackState,

      message: _in.lang[_in.error.response.data.error]
        ? _in.lang[_in.error.response.data.error]
        : _in.lang['UNEXPECTED_SERVER_ERROR']
    })
  } else {
    _in.setFeedbackState({
      ..._in.feedbackState,

      message:
        _in.feedbackState && _in.feedbackState.message
          ? _in.lang[_in.feedbackState.message]
          : _in.lang['UNEXPECTED_CLIENT_ERROR']
    })
  }
} // handleUserFeedback
