import React, { useContext } from 'react'

import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

import { StoreContext } from '../../../contexts/StoreProvider'
import SGrid from '../../../components/SGrid'

function CheckOutProducts ({ products = [], onClick = () => {} }) {
  const { store, dispatchStore, actions, func } = useContext(StoreContext)
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow style={{ background: store.styles.primaryColor }}>
            <TableCell style={{ color: 'white' }} align='left'>
              <strong>{store.lang['ACTIONS']}</strong>
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              {store.lang['']}
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              <strong>{store.lang['TITLE']}</strong>
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              <strong>{store.lang['PRICE']}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            products.map((product, key) => (
              <TableRow key={key}>
                <TableCell align='left'>
                  <IconButton>
                    <DeleteIcon
                      onClick={_in => {
                        onClick({ action: 'DELETE_PRODUCT', id: product.id })
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <img
                    sgridxs={1}
                    sgridsm={1}
                    //onClick={e => onClick({ action: 'VIEW_DETAILS' })}
                    style={{}}
                    src={product.imgSrc}
                    width='35px'
                    height='auto'
                  />
                </TableCell>
                <TableCell>
                  <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                    {product.title}
                  </div>
                </TableCell>
                <TableCell>
                  <strong>{product.price + ' €'}</strong>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
    </TableContainer>
  )
}

export default CheckOutProducts

const Row = ({ products }) => {
  const { store, dispatchStore, actions, func } = useContext(StoreContext)
  return (
    <TableContainer component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow style={{ background: store.styles.primaryColor }}>
            <TableCell style={{ color: 'white' }} align='left'>
              {store.lang['ACTIONS']}
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              {store.lang['']}
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              {store.lang['TITLE']}
            </TableCell>
            <TableCell style={{ color: 'white' }} align='left'>
              {store.lang['PRICE']}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            products.map((product, key) => (
              <TableRow key={key}>
                <TableCell align='left'>
                  <IconButton>
                    <DeleteIcon
                      actions={[
                        {
                          action: 'DELETE_PRODUCT',
                          id: product.id
                        }
                      ]}
                      onClick={_in => {
                        //onClick({ action: _in.action, id: data.id })
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <img
                    sgridxs={1}
                    sgridsm={1}
                    //onClick={e => onClick({ action: 'VIEW_DETAILS' })}
                    style={{}}
                    src={product.imgSrc}
                    width='35px'
                    height='auto'
                  />
                </TableCell>
                <TableCell>
                  <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                    {product.title}
                  </div>
                </TableCell>
                <TableCell>
                  <strong>{product.price + ' €'}</strong>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter></TableFooter>
      </Table>
    </TableContainer>
  )

  {
    /**
  return (
    <div
      style={{
        textAlign: 'center',
        //border: '1px solid blue',
        padding: '7px',
        width: '100%',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
      }}
    >
      <SGrid xs={3} sm={3}>
        <div sgridxs={1} sgridsm={1}>
          <div style={{ paddingTop: '17px' }}>
            <DeleteIcon />
          </div>
        </div>
        <img
          sgridxs={1}
          sgridsm={1}
          //onClick={e => onClick({ action: 'VIEW_DETAILS' })}
          style={{}}
          src={product.imgSrc}
          width='35px'
          height='auto'
        />
        <div sgridxs={8} sgridsm={8} style={{ fontWeight: 'bold' }}>
          <div
            style={{
              position: 'absolute',
              textAlign: 'left',
              paddingTop: '17px'
            }}
          >
            {product.title}
          </div>
        </div>
        <div sgridxs={2} sgridsm={2}>
          <div
            style={{
              position: 'absolute',
              textAlign: 'left',
              paddingTop: '17px'
            }}
          >
            {product.price} €
          </div>
        </div>
      </SGrid>
    </div>
  ) */
  }
}
