import React, { useState, useContext, useEffect } from 'react'

import { navigate } from '@reach/router'

import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Autocomplete from '@material-ui/lab/Autocomplete'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import Checkbox from '@material-ui/core/Checkbox'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import MoreVertIcon from '@material-ui/icons/MoreVert'

export default function RowWithActions ({
  primary = '',
  secondary = '',
  id = '',
  onDelete = () => {},
  actions = (
    <IconButton onClick={e => onDelete(id)} edge='end' aria-label='comments'>
      <DeleteIcon />
    </IconButton>
  )
}) {
  const useRowStyles = makeStyles({
    container: {
      listStyleType: 'none'
    }
  })
  let classesRowStyles = useRowStyles()

  return (
    <Paper>
      <ListItem
        classes={{ container: classesRowStyles.container }}
        // style={{ padding: '0px 5px 0px 10px' }}
        //button
        //onClick={console.log('hola')}
      >
        <ListItemText primary={primary} secondary={secondary} />
        <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>
      </ListItem>
    </Paper>
  )
}
