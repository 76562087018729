import React, { useContext, forwardRef } from 'react'

import { StoreContext } from '../../../contexts/StoreProvider'

import CheckOutClient from './CheckOutClient'
import CheckOutProducts from './CheckOutProducts'
import CheckOutCreditCard from './CheckOutCreditCard'

function CheckOutView (
  {
    name = '',
    phone = '',
    email = '',
    errorClient = { name: '', phone: '', email: '' },
    onChange = () => {},
    onClick = () => {},
    products = [],
    amount = 0,
    currency = 'EUR',
    billingDetails = {}
  },
  ref
) {
  const { store, dispatchStore } = useContext(StoreContext)

  const checkOutCreditCartRef = React.useRef(null)

  React.useImperativeHandle(ref, () => ({
    pay: async () => {
      let resPayment = await checkOutCreditCartRef.current.pay()
      if (!resPayment.error) return resPayment
      else throw resPayment
    }
  }))

  return (
    <div style={{ margin: '15px' }}>
      <Title name={'1.' + store.lang['PERSONAL_DATA']} />
      <CheckOutClient
        error={errorClient}
        name={name}
        phone={phone}
        email={email}
        error={errorClient}
        onChange={data => onChange(data)}
      />
      <br />
      <Title name={'2.' + store.lang['PRODUCTS']} />
      <CheckOutProducts products={products} onClick={data => onClick(data)} />
      <br />
      <Title name={'3.' + store.lang['PAYMENT']} />
      <CheckOutCreditCard
        ref={checkOutCreditCartRef}
        amount={amount}
        currency={currency}
        billingDetails={billingDetails}
        onClick={data => onClick(data)}
      />
    </div>
  )
}

CheckOutView = forwardRef(CheckOutView)
export default CheckOutView

const Title = ({ name = '' }) => {
  return (
    <div
      style={{
        fontWeight: 'bold',
        fontSize: '1.3em',
        marginBottom: '10px',
        paddingTop: '10px'
      }}
    >
      {name}
    </div>
  )
} //Title
