import React, { useReducer, createContext, useContext, useEffect } from 'react'

import { useCustomStyles } from '../common/Config'

import lang from '../assets/lang.json'

import { ACTIVE_TRACKER } from '../common/Config'

import useStorage from '../hooks/UseStorage'

export const StoreContext = createContext()

export const RESET = 'reset'
export const SET_LANG = 'setlang'
export const SET_STYLES = 'setStyles'
export const ADD_ITEM_TO_CART = 'addItemToCart'
export const SET_ITEMS_TO_CART = 'setItemsToCart'

function StoreProvider (props) {
  const styles = useCustomStyles()

  const [
    setStorageShoppingCart,
    getStorageShoppingCart,
    deleteStorageShoppingCart
  ] = useStorage({ storage: sessionStorage, id: 'shoppingcart' })

  //console.log('sessionStorage_Store', window.sessionStorage)
  //sessionStorage.setItem(JSON.stringify('hola'), 'test')
  const defaultStore = {
    lang: lang['es'],
    shoppingCart: [],
    styles,
    client: {},
    userapp: {}, //metadataUser if configured
    permissionsMap: new Map(),
    currentTimeStamp: Date.now(),
    activeTracker: ACTIVE_TRACKER
  }

  const [store, dispatchStore] = useReducer(reducerStore, defaultStore)

  const reset = data => {
    dispatchStore({ type: RESET })
  }

  function setLang (_lang) {
    dispatchStore({
      type: SET_LANG,
      payload: {
        _lang: _lang
      }
    })
  }

  function resetItemsToCart () {
    dispatchStore({
      action: SET_ITEMS_TO_CART,
      payload: { items: [] }
    })
  }

  function addItemToCart (_item) {
    for (let i = 0; i < store.shoppingCart.length; i++) {
      if (store.shoppingCart[i].id === _item.id) {
        return 'already_added'
      }
    }

    dispatchStore({ action: ADD_ITEM_TO_CART, payload: { item: _item } })
    return 'done'
  } //addItemToCart

  function deleteItemFromCart (id) {
    let items = [...store.shoppingCart]

    let itemsAfterDelete = items.filter(it => {
      if (it.id === id) {
        return false
      } else {
        return true
      }
    })

    dispatchStore({
      action: SET_ITEMS_TO_CART,
      payload: { items: itemsAfterDelete }
    })
  } //deleteItemFromCart

  function getItemsFromStore () {
    let shoppingCartItems = getStorageShoppingCart()
    if (!shoppingCartItems) return
    dispatchStore({
      action: SET_ITEMS_TO_CART,
      payload: { items: shoppingCartItems }
    })
  } //getItemsFromStore

  function reducerStore (state, action) {
    switch (action.action) {
      case RESET:
        return defaultStore
      case SET_LANG:
        return {
          ...state,
          lang: lang[action.payload._lang]
        }

      case ADD_ITEM_TO_CART:
        setStorageShoppingCart([...state.shoppingCart, action.payload.item])
        return {
          ...state,
          shoppingCart: [...state.shoppingCart, action.payload.item]
        }

      case SET_ITEMS_TO_CART:
        setStorageShoppingCart(action.payload.items)
        return {
          ...state,
          shoppingCart: action.payload.items
        }

      default:
        return state
    }
  } //reducerStore

  return (
    <StoreContext.Provider
      value={{
        store,
        dispatchStore,
        actions: {
          RESET,
          SET_LANG
        },
        func: {
          reset,
          setLang,
          addItemToCart,
          deleteItemFromCart,
          resetItemsToCart,
          getItemsFromStore
        }
      }}
    >
      {props.children}
    </StoreContext.Provider>
  )
} //StoreProvider

export default StoreProvider
