import React, { useContext } from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { StoreContext } from '../contexts/StoreProvider'

import Wrapper from '../components/Wrapper'

function DialogDeleteConfirm ({ open = false, code = '', onClick = () => {} }) {
  const { store, dispatchStore } = useContext(StoreContext)

  return (
    <Dialog aria-labelledby='customized-dialog-title' open={open}>
      <DialogTitle id='customized-dialog-title' onClose={() => {}}>
        {store.lang['CONFIRM_DELETE']}
      </DialogTitle>
      <DialogContent dividers></DialogContent>
      <DialogActions>
        <Button
          {...store.styles.ButtonPrimaryB()}
          onClick={e => onClick({ type: 'CANCEL' })}
          color='primary'
        >
          {store.lang['CLOSE']}
        </Button>
        <Button
          {...store.styles.ButtonPrimary()}
          onClick={e => onClick({ type: 'OK', code })}
          color='primary'
        >
          {store.lang['OK']}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogDeleteConfirm
