import React from 'react'
import { makeStyles } from '@material-ui/styles'

import clsx from 'clsx'

export const ACTIVE_TRACKER = true


/* export const firebaseConfig = {
  apiKey: "AIzaSyDffT7zW_nGIdOEVsy_gXD-LVnHqWiqWYM",
  authDomain: "development-environment-7fe5c.firebaseapp.com",
  projectId: "development-environment-7fe5c",
  storageBucket: "development-environment-7fe5c.appspot.com",
  messagingSenderId: "125569960899",
  appId: "1:125569960899:web:2797897323b5ed38514b76",
  measurementId: "G-CPHNRJYV5Q"
}
export const URL = 'http://localhost:3000/'
export const stripe_PK = 'pk_test_qnOcUKeCyqMcFScQZInvM10q' */

export const URL = 'estetica40-cli.web.app/'
export const stripe_PK = 'pk_live_1zXJ853KRurAquwrMHKZpLc7'
export const firebaseConfig = {
  apiKey: 'AIzaSyC-vwk_4jl-suOtt0M5qRSxhXXeIuE_dwY',
  authDomain: 'estetica40-cli.firebaseapp.com',
  projectId: 'estetica40-cli',
  storageBucket: 'estetica40-cli.appspot.com',
  messagingSenderId: '665299331512',
  appId: '1:665299331512:web:cdbd59ccc5c93d5098c74d',
  measurementId: 'G-792650EG28'
}

export function getUrl() {
  if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev') {
    return 'https://next.api.slp.richnologies.io/api'
  }

  return ''
} //getUrl

export function getAccessData(_in) {
  let arrTutorAccess = [
    'activitiesFloating_W',
    'activitiesFloating_R',
    'activityDetailsMain_R',
    'activityDetailsMain_W',
    'activityDetailsMainTutorFiles_R',
    'activityDetailsMainTutorFiles_W',
    'activityDetailsFilterStudent',
    'activityDetailsStudentFiles_R',
    'activityNotFilterByUser', //si es tutor puede ver las actividades de todos los alumnos
    'activityNotFilerByPublishDate', //si es tutor se permite ver las tareas programadas a futuro.
    'ActivityNewActivity',
    'ActivityItemDelete'
  ]
  let arrStudentAccess = [
    'activitiesFloating_R',
    'activityDetailsMain_R',
    'activityDetailsMainSeeActivity',
    'activityDetailsMainTutorFiles_R',
    'activityDetailsStudentFiles_R',
    'activityDetailsStudentFiles_W'
  ]

  let accessArray = []
  if (_in && _in.type === 'tutor') accessArray = arrTutorAccess
  if (_in && _in.type === 'student') accessArray = arrStudentAccess

  let accessMap = new Map()
  for (let i = 0; i < accessArray.length; i++) {
    accessMap.set(accessArray[i], true)
  }

  return accessMap
} //getAccessData

export function checkAccess(_in, _permissionsArr) {
  for (let i = 0; i < _permissionsArr.length; i++) {
    if (_in.permissionsMap.get(_permissionsArr[i])) return true
  }
  return false
} //checkAccess

export function useCustomStyles() {
  let primaryColor = 'rgb(179,29,143)'
  let secondaryColor = 'rgb(70,197,18)' //'rgb(38, 203, 124)'

  let useStylesButtonPrimary = makeStyles({
    root: {
      width: '100%',
      backgroundColor: primaryColor,
      color: 'white',
      '&:hover': {
        backgroundColor: primaryColor
      }
    }
  })
  let classesButtonPrimary = useStylesButtonPrimary()

  let useStylesButtonPrimaryB = makeStyles({
    root: {
      width: '100%',
      color: primaryColor,
      backgroundColor: 'white',
      border: '1px solid ' + primaryColor,

      '&:hover': {
        backgroundColor: 'white'
      }
    }
  })
  let classesButtonPrimaryB = useStylesButtonPrimaryB()

  let useStylesButtonSecondary = makeStyles({
    root: {
      width: '100%',
      backgroundColor: secondaryColor,
      color: 'white',
      '&:hover': {
        backgroundColor: secondaryColor
      }
    }
  })
  let classesButtonSecondary = useStylesButtonSecondary()

  let useStylesButtonSecondaryB = makeStyles({
    root: {
      width: '100%',
      color: secondaryColor,
      backgroundColor: 'white',
      border: '1px solid ' + secondaryColor,
      '&:hover': {
        backgroundColor: 'white'
      }
    }
  })
  let classesButtonSecondaryB = useStylesButtonSecondaryB()

  let useStylesTextField = makeStyles({
    root: {
      width: '100%'
      //border: '1px solid ' + primaryColor,
      //borderRadius: '5px'
    }
  })
  let classesTextField = useStylesTextField()

  let useStylesLink = makeStyles({
    root: props => ({
      color: props.color,
      textDecoration: 'underline',
      fontWeight: 'bold',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    })
  })
  let classesLinkPrimary = useStylesLink({ color: primaryColor })

  let useStylesAutocomplete = makeStyles({
    root: {}
  })
  let classesAutocomplete = useStylesAutocomplete()

  return {
    primaryColor: primaryColor,
    secondaryColor: secondaryColor,
    LinkStylesPrimary: _in => {
      return classesLinkPrimary.root
    },
    ButtonPrimary: _in => {
      return {
        classes: {
          root: classesButtonPrimary.root
        },
        variant: 'contained'
      }
    },
    ButtonPrimaryB: _in => {
      return {
        classes: {
          root: classesButtonPrimaryB.root
        },
        variant: 'contained'
      }
    },
    ButtonSecondary: _in => {
      return {
        classes: {
          root: classesButtonSecondary.root
        },
        variant: 'contained'
      }
    },
    ButtonSecondaryB: _in => {
      return {
        classes: {
          root: classesButtonSecondaryB.root
        },
        variant: 'contained'
      }
    },

    TextField: _in => {
      return {
        classes: {
          root: classesTextField.root
        }
      }
    },

    Autocomplete: _in => {
      return {
        className: classesAutocomplete.root
      }
    }
  }
} // useCustomStyles
