import React, { useState, useEffect, useReducer } from 'react'

import firebase, { auth, firestore } from './common/firebase'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Router, navigate } from '@reach/router'

import { useCustomStyles, stripe_PK } from './common/Config'
import { getPersistentData, setPersistentData } from './common/Utils'

import Login from './pages/Login'
import Register from './pages/Register'
import BackOffice from './pages/BackOffice'
import MagicLink from './pages/MagicLink'
import Main from './pages/Main'
import Home from './pages/Home'
import SensorDetailsContainer from './pages/sensor/SensorDetailsContainer'
import Backup from './pages/Backup'
import MiniBooks from './pages/MiniBooks'
import MinibooksBackoffice from './pages/minibooks/MinibooksBackoffice'
import MinibooksDetails from './pages/MinibooksDetails'
import CheckOutContainer from './pages/CheckOutContainer'
import MinibooksPaymentsContainer from './pages/minibooks/payments/MinibooksPaymentsContainer'
import Legal from './pages/Legal'
import CookiesPolicy from './pages/CookiesPolicy'
import Privacy from './pages/Privacy';
import TrackingReport from './pages/minibooks/reports/TrackingReport';

import StoreProvider from './contexts/StoreProvider'

import './App.css'

const stripePromise = loadStripe(stripe_PK)

function App () {
  return (
    <div className='App'>
      <Elements stripe={stripePromise}>
        <StoreProvider>
          <Router>
            <Home path='/' />
            <Backup path='/backup' />
            <Login path='/login' />

            <CookiesPolicy path='/cookies' />
            <MinibooksBackoffice path='/minibooks'>
              <MiniBooks path='/' />
              <MinibooksDetails path='/:slug' />
              <CheckOutContainer path='/checkout' />
              <MinibooksPaymentsContainer path='/payments/:id' />
              <TrackingReport path='/tracking-report' />
              <Register path='/register' />
              <Legal path='/legal' />
              <Privacy path='/privacy' />
              <CookiesPolicy path='/cookies' />
            </MinibooksBackoffice>
            <BackOffice path='/backoffice'>
              <Main path='/' />
            </BackOffice>
          </Router>
        </StoreProvider>
      </Elements>
    </div>
  )
}

export default App
