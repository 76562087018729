import React, { useEffect } from 'react'

import { Router, navigate } from '@reach/router'

function Home () {
  useEffect(() => {
    navigate('/minibooks/')
  }, [])
  return <div>HOME</div>
}

export default Home
