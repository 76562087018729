import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

/**
 *visible: true | false
 *size
 */

const styleMain = {
  position: 'fixed',
  zIndex: '10000',
  left: '0',
  right: '0',
  bottom: '0',
  top: '0'
}

const styleCircular = {
  position: 'fixed',
  zIndex: '100',
  top: '45%',
  left: '45%'
}

const SProgress = props => {
  if (!props.visible) return null
  return (
    <div style={styleMain}>
      <div style={styleCircular}>
        <CircularProgress size={props.size} />
      </div>
    </div>
  )
}

export default SProgress
