import React, { useContext, useEffect, useState } from 'react'

import firebase, { auth, firestore } from '../../../common/firebase'

import { Router, navigate } from '@reach/router'

import { StoreContext } from '../../../contexts/StoreProvider'

import useCrud from '../../../hooks/UseCrud'
import useCheck from '../../../hooks/UseCheck'
import useFeedback from '../../../hooks/UseFeedBack'
import useProgress from '../../../hooks/UseProgress'
import SProgress from '../../../components/SProgress'
import LayoutBOContent from '../../../components/LayoutBOContent'
import Feedback from '../../../components/Feedback'

import SGrid from '../../../components/SGrid'
import Wrapper from '../../../components/Wrapper'

import MinibooksPaymentsView from './MinibooksPaymentsView'

const defaultState = {
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

const defaultPayment = {
  id: '',
  name: '',
  email: '',
  phone: '',
  products: {},
  productsArr: []
}

function MinibooksPaymentsContainer ({ id }) {
  const { store, dispatchStore } = useContext(StoreContext)

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)
  const [payment, setPayment] = useState(defaultPayment)

  const [checkIsState, handleCheckState] = useCheck({
    conditions: [
      {
        name: state.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: state.capacity,
        errorName: 'capacity',
        check: ['required', 'number', ['>', 0, 'field_bigger_0']]
      },
      {
        name: state.userappMail,
        errorName: 'userappMail',
        check: ['required', ['function', () => {}, state.param]]
      }
    ],
    setErrorState: setStateError,
    errorState: {},
    defaultErrorState: defaultStateError
  })

  const [crudState] = useCrud({
    arrCol: ['/resources/', state.id]
  })
  const [crudPayments] = useCrud({
    arrCol: ['/payments/', id]
  })

  async function handleClick () {
    //create/upate resource
    let check = handleCheckState()
    if (check) {
      feedback.error(store.lang['USER_DATA_ERROR'])
      return
    }

    try {
      showProgress()
      let batch = firestore.batch()

      crudState.create({
        batch,
        payload: {}
      })

      batch
        .commit()
        .then(res => {
          feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (err) {
      handleGlobalError({
        error: err,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    } finally {
      hideProgress()
    }
  } //handleClickCreateResource

  async function getPayment () {
    try {
      let paymentsFindRef = crudPayments.findDocRef({})
      let res = await paymentsFindRef.get()
      let paymentRes = { ...res.data(), id: res.id }
      paymentRes.productsArr = []
      for (let k in paymentRes.products) {
        paymentRes.productsArr.push({ ...paymentRes.products[k] })
      }
      paymentRes.productsArr = paymentRes.productsArr.sort((a, b) =>
        a.id.localeCompare(b.id)
      )

      setPayment(paymentRes)
      return paymentRes
    } catch (err) {
      throw err
    }
  } //getPayment

  async function handleClickDownload (data) {
    switch (data.action) {
      case 'DOWNLOAD_FILE':
        showProgress()
        try {
          let donwloadFile = firebase.functions().httpsCallable('downloadFile')
          let url = await donwloadFile({
            filePath: '' + data.payload.filePath
          })

          console.log('url', url.data)

          window.open(url.data)

          let updateDownloads = firebase
            .functions()
            .httpsCallable('updateDownloads')

          let res = await updateDownloads({
            paymentId: data.paymentId,
            productId: data.payload.id
          })

          let productsArr = []
          if (res.data && res.data.products) {
            for (let k in res.data.products) {
              productsArr.push({ ...res.data.products[k] })
            }
            console.log('productsArr', productsArr)
            productsArr = productsArr.sort((a, b) => a.id.localeCompare(b.id))
            setPayment({ ...payment, productsArr: productsArr })
          }
        } catch (err) {
          if (err.code === 'out-of-range') {
            feedbackFunc.error(store.lang['max_download_exceeded'])
          } else {
            feedbackFunc.error(store.lang['UNEXPECTED_SERVER_ERROR'])
          }
        }
        hideProgress()
        break
    }
  } //handleClickDownload

  useEffect(() => {
    getPayment()
  }, [])

  /* useEffect(() => {
    let donwloadFile = firebase.functions().httpsCallable('downloadFile')
    try {
      donwloadFile()
        .then(url => {
          console.log('url', url)
        })
        .catch(err => {
          console.log('ko')
        })
    } catch (err) {
      console.log('err', err)
    }
  }, [])*/

  return (
    <div style={{ margin: '15px' }}>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      {/*<DialogDeleteConfirm
        open={dialogConfirm.showModal}
        code={dialogConfirm.code}
        onClick={({ type, code, payload }) => {
          if (type === 'CANCEL') {
          }
          if (type === 'OK') {
            switch (code) {
              case 'deleteGuest':
                handleDeleteGuest(dialogConfirm.payload)
                break
              case 'deleteResource':
                handleDeleteResource(dialogConfirm.payload)
                break
            }
          }
          setDialogConfirm(defaultDialogConfirm)
        }}
      />*/}

      <LayoutBOContent>
        <SGrid xs={12} sm={12}>
          <Wrapper
            visible={true}
            compNotVisible={undefined}
            //errorMsg={props.error.field}
          >
            <MinibooksPaymentsView
              email={payment.email}
              products={payment.productsArr}
              onClick={data => handleClickDownload({ ...data, paymentId: id })}
            />
          </Wrapper>
        </SGrid>
      </LayoutBOContent>
    </div>
  )
}

export default MinibooksPaymentsContainer
