import React, { useContext, useEffect, useState, useRef } from 'react'

import firebase, { auth, firestore } from '../../common/firebase'

import { Router, navigate } from '@reach/router'

import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
//import { DateRangePicker, DateRange } from '@material-ui/lab/DateRangePicker'

import { StoreContext } from '../../contexts/StoreProvider'
import useCrud from '../../hooks/UseCrud'
import useCheck from '../../hooks/UseCheck'
import useFeedback from '../../hooks/UseFeedBack'
import useProgress from '../../hooks/UseProgress'
import usePagination from '../../hooks/UsePagination'
import SProgress from '../../components/SProgress'
import LayoutBOContent from '../../components/LayoutBOContent'
import Feedback from '../../components/Feedback'

import SGrid from '../../components/SGrid'
import Wrapper from '../../components/Wrapper'
import SensorDetailsView from './SensorDetailsView'
import ActionsTableSensor from './ActionsTableSensor'
import DialogComments from './DialogComments'
import DialogCommentsContainer from './DialogCommentsContainer'

import {
  dateUTCPlus1,
  exportExcel,
  dateToTextfieldPlus1
} from '../../common/Utils'

const defaultState = {
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

const defaultSensorRes = {}

const defaultIotData = {
  tableHeaders: [],
  tableData: []
}

const defaultFilters = {
  dateIni: dateToTextfieldPlus1(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  ),
  dateEnd: dateToTextfieldPlus1(new Date())
}

const defaultDialogComment = {
  open: false,
  id: '',
  comment: ''
}

const defaultErrorDialogComment = {
  id: '',
  comment: ''
}

const defaultPagination = {
  style: { overflow: 'visible' },
  rowsPerPageOptions: [5, 10, 25, { label: 'All', value: -1 }],
  colSpan: 1,
  count: 0,
  rowsPerPage: 5,
  page: 0
}

function SensorDetailsContainer ({ id }) {
  const { store, dispatchStore } = useContext(StoreContext)

  const paginationRef = useRef({
    style: { overflow: 'visible' },
    rowsPerPage: 0,
    count: 0,
    page: 0
  })

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)
  const [sensorRes, setSensorRes] = useState(defaultSensorRes)
  const [iotData, setIotData] = useState(defaultIotData)
  const [filters, setFilters] = useState(defaultFilters)
  const [pagination, setPagination] = useState(defaultPagination)

  const [dialogComment, setDialogComment] = useState(defaultDialogComment)
  const [errorDialogComment, setErrorDialogComment] = useState(
    defaultErrorDialogComment
  )

  const [paginationAux, funcPagination, configPagination] = usePagination({
    dataIn: iotData.tableData,
    rowsPerPageOptions: [5, 10, 25, { label: 'All', value: -1 }]
  })
  let paginationRef1 = React.useMemo(() => {
    return {
      style: { overflow: 'visible' },
      rowsPerPage: paginationAux.rowsPerPage,
      count: paginationAux.count,
      page: paginationAux.page,
      rowsPerPageOptions: configPagination.rowsPerPageOptions
    }
  }, [paginationAux])

  /*
  const onChangePageMemo = React.useCallback(data => {
    funcPagination.setPage(data)
  }, [])
  const onChangeRowsPerPageMemo = React.useCallback(data => {
    funcPagination.setRowsPerPage(data)
  }, [])

  const paginationMemo = {
    rowsPerPageOptions: pagination.rowsPerPageOptions,
    colSpan: pagination.colSpan,
    count: pagination.count,
    rowsPerPage: pagination.rowsPerPage,
    page: pagination.page,
    onChangePage: onChangePageMemo,
    onChangeRowsPerPage: onChangeRowsPerPageMemo
  }*/

  const [checkIsDialogComment, handleCheckDialogComment] = useCheck({
    conditions: [
      {
        name: dialogComment.comment,
        errorName: 'comment',
        check: ['required']
      }
    ],
    setErrorState: setErrorDialogComment,
    errorState: {},
    defaultErrorState: defaultErrorDialogComment
  })

  const [checkIsState, handleCheckState] = useCheck({
    conditions: [
      {
        name: state.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: state.capacity,
        errorName: 'capacity',
        check: ['required', 'number', ['>', 0, 'field_bigger_0']]
      },
      {
        name: state.userappMail,
        errorName: 'userappMail',
        check: ['required', ['function', () => {}, state.param]]
      }
    ],
    setErrorState: setStateError,
    errorState: {},
    defaultErrorState: defaultStateError
  })

  const [crudIotData] = useCrud({
    arrCol: ['/iotdata/']
  })
  const [crudSensors] = useCrud({
    arrCol: ['/sensors/', id]
  })

  useEffect(() => {
    showProgress()
    getDataSensor(id)
      .then(res => {})
      .catch(err => {})
      .finally(() => {
        hideProgress()
      })
  }, [])

  async function getDataSensor (_id) {
    let sensorDoc = crudSensors.findDocRef({})

    let sensorFind = await sensorDoc.get()

    let sensor = { id: sensorFind.id, ...sensorFind.data() }
    setSensorRes(sensor)

    let iotdataRef = crudIotData.find({})
    let iotDataFind = await iotdataRef
      .where('hardware_serial', '==', sensor.hardwareSerial)
      .where('createdAt', '<=', new Date(filters.dateEnd + 'T23:59'))
      .where('createdAt', '>=', new Date(filters.dateIni + 'T00:00'))
      .orderBy('createdAt', 'desc')
      .get()

    let iotdata = crudIotData.getResult({ data: iotDataFind })

    let headersToShow = []
    headersToShow.push({ name: 'createdAt', alias: store.lang['DATE'] })
    headersToShow.push({ name: 'comment', alias: store.lang['COMMENT'] })
    headersToShow = [...headersToShow, ...sensor.columns]

    /*for (let k = 0; k < iotdata.length; k++) {
      if (iotdata[k].payload_fields) {
        for (let key in iotdata[k].payload_fields) {
          headersToShow.push({ name: key, alias: key })
        }
        break
      }
    }*/

    let dataToShow = []
    for (let k = 0; k < iotdata.length; k++) {
      if (iotdata[k].payload_fields) {
        let dataToShowAux = {}
        for (let key in iotdata[k].payload_fields) {
          dataToShowAux[key] = iotdata[k].payload_fields[key]
        }
        let createdAtAux = iotdata[k].createdAt
          ? dateUTCPlus1(new Date(iotdata[k].createdAt.seconds * 1000))
          : ''
        dataToShow.push({
          ...dataToShowAux,
          createdAt: createdAtAux,
          comment: iotdata[k].comment ? iotdata[k].comment : '',
          id: iotdata[k].id
        })
      }
    }

    setIotData({ tableHeaders: headersToShow, tableData: dataToShow })
  } //getDataSensor

  async function handleClick () {
    //create/upate resource
    let check = handleCheckState()
    if (check) {
      feedback.error(store.lang['USER_DATA_ERROR'])
      return
    }

    try {
      showProgress()
      let batch = firestore.batch()

      crudIotData.create({
        batch,
        payload: {}
      })

      batch
        .commit()
        .then(res => {
          feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (err) {
      handleGlobalError({
        error: err,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    } finally {
      hideProgress()
    }
  } //handleClickCreateResource

  function handleClickExportExcel (_in) {
    let dataRes = []

    for (let i = 0; i < iotData.tableData.length; i++) {
      let dataExcel = {}
      for (let k = 0; k < iotData.tableHeaders.length; k++) {
        dataExcel[iotData.tableHeaders[k].name] =
          iotData.tableData[i][iotData.tableHeaders[k].name]
      }
      dataRes.push(dataExcel)
    }

    exportExcel(dataRes)
  } //handleClickExportExcel

  function handleOnChange ({ name, value }) {
    setFilters({ ...filters, [name]: value })
  } //handleOnChange

  function handleSearch ({}) {
    showProgress()
    getDataSensor(id)
      .then(res => {})
      .catch(err => {})
      .finally(() => {
        hideProgress()
      })
  } //handleSearch

  function handleClickDialogComments (data) {
    switch (data.action) {
      case 'SAVE':
        let check = handleCheckDialogComment()
        if (check) {
          feedbackFunc.error(store.lang['USER_DATA_ERROR'])
          return
        }

        try {
          showProgress()
          let batch = firestore.batch()

          /**/ crudIotData.update({
            batch,
            _col: '/iotdata/',
            id: dialogComment.id,
            payload: { comment: dialogComment.comment }
          })

          batch
            .commit()
            .then(res => {
              let iotdataAux = [...iotData.tableData]

              for (let i = 0; i < iotdataAux.length; i++) {
                if (iotdataAux[i].id === dialogComment.id) {
                  iotdataAux[i].comment = dialogComment.comment
                  setIotData({ ...iotData, tableData: iotdataAux })
                }
              }
              setDialogComment(defaultDialogComment)
              feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
            })
            .catch(err => {
              handleGlobalError({
                error: err,
                navigate: navigate,
                url: '/login',
                lang: store.lang
              })
            })
        } catch (err) {
          console.log('err', err)
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        } finally {
          hideProgress()
        }

        break
      case 'CANCEL':
        setDialogComment(defaultDialogComment)
        break
    } //switch
  } //handleClickDialogComments

  function handleClickSensorDetailsView (data) {
    switch (data.action) {
      case 'ADD_COMMENT':
        let iotdataAux = [...iotData.tableData]

        let commentAux = ''
        for (let i = 0; i < iotdataAux.length; i++) {
          if (iotdataAux[i].id === data.id) {
            commentAux = iotdataAux[i].comment
          }
        }
        setDialogComment(dialogCommentPrev => {
          return {
            ...dialogCommentPrev,
            open: true,
            id: data.id,
            comment: commentAux
          }
        })
        break

      case 'PAGINATION_ONCHANGEPAGE':
        showProgress()
        funcPagination.setPage(data.payload.newPage)
        hideProgress()
        //console.log('Pagination_OnChange', data.payload)
        break

      case 'PAGINATION_ONCHANGEROWSPERPAGE':
        showProgress()
        funcPagination.setRowsPerPage(data.payload.rowsPerPage)
        funcPagination.startPage()
        hideProgress()
        break
    }
  } //handleClickSensorDetailsView

  /**/ const handleClickSensorDetailsViewMemo = React.useCallback(
    handleClickSensorDetailsView,
    [iotData.tableData]
  )

  return (
    <div>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      <DialogComments
        error={errorDialogComment}
        open={dialogComment.open}
        comment={dialogComment.comment}
        /**/ onChange={_in =>
          setDialogComment({ ...dialogComment, [_in.name]: _in.value })
        }
        onClick={data => handleClickDialogComments(data)}
      />

      {/*<DialogDeleteConfirm
        open={dialogConfirm.showModal}
        code={dialogConfirm.code}
        onClick={({ type, code, payload }) => {
          if (type === 'CANCEL') {
          }
          if (type === 'OK') {
            switch (code) {
              case 'deleteGuest':
                handleDeleteGuest(dialogConfirm.payload)
                break
              case 'deleteResource':
                handleDeleteResource(dialogConfirm.payload)
                break
            }
          }
          setDialogConfirm(defaultDialogConfirm)
        }}
      />*/}

      {/* */}

      <LayoutBOContent>
        <Wrapper
          visible={true}
          //compNotVisible={undefined}
          //errorMsg={props.error.field}
        >
          <SensorDetailsView
            name={sensorRes.name}
            description={sensorRes.description}
            hardwareSerial={sensorRes.hardwareSerial}
            tableHeaders={iotData.tableHeaders}
            //tableData={iotData.tableData}
            tableData={paginationAux.filteredDataIn}
            pagination={paginationRef1}
            onClick={handleClickSensorDetailsViewMemo}
            actions={
              <>
                <ActionsTableSensor
                  onClick={({ action, name, value }) => {
                    if (action === 'EXPORT_EXCEL') handleClickExportExcel({})
                    if (action === 'ONCHANGE') handleOnChange({ name, value })
                    if (action === 'SEARCH') handleSearch({})
                    if (action === 'RELOAD') handleSearch({})
                  }}
                  dateIni={filters.dateIni}
                  dateEnd={filters.dateEnd}
                />
                <br />
              </>
            }
          />
        </Wrapper>
      </LayoutBOContent>
    </div>
  )
}

export default SensorDetailsContainer

/*

const ActionsTableAux = ({ onClick = () => {} }) => {
  const { store, dispatchStore } = useContext(StoreContext)
  return (
    <Paper>
      <div
        style={{ position: 'relative', textAlign: 'right', padding: '10px' }}
      >
        <SGrid xs={12} sm={6}>
          <div
            style={{ position: 'relative', textAlign: 'left', padding: '10px' }}
            sgridxs={12}
            sgridsm={9}
          >
            <SGrid xs={12} sm={3}>
              <TextField
                type='date'
                label={store.lang['FROM']}
                style={{ backgroundColor: 'white' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                type='date'
                label={store.lang['TO']}
                style={{ backgroundColor: 'white' }}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <Button
                aria-label='upload picture'
                component='span'
                {...store.styles.ButtonSecondaryB()}
                style={{ marginTop: '10px' }}
                onClick={e => navigate('/')}
                size='small'
              >
                <SearchIcon />
              </Button>
            </SGrid>
          </div>
          <ButtonGroup
            variant='contained'
            color='primary'
            aria-label='contained primary button group'
            sgridxs={12}
            sgridsm={3}
          >
            <Button
              aria-label='upload picture'
              component='span'
              {...store.styles.ButtonSecondaryB()}
              onClick={e => navigate('/')}
              size='small'
            >
              <ArrowBackIcon />
            </Button>

            <Button
              aria-label='upload picture'
              component='span'
              {...store.styles.ButtonSecondaryB()}
              size='small'
              onClick={e => onClick({ action: 'EXPORT_EXCEL' })}
            >
              <CloudDownloadIcon />
            </Button>
          </ButtonGroup>
        </SGrid>
      </div>
    </Paper>
  )
}


const ActionsTable = ({ onClick = () => {} }) => {
  const { store, dispatchStore } = useContext(StoreContext)
  return (
    <Paper>
      <div
        style={{ position: 'relative', textAlign: 'right', padding: '10px' }}
      >
        <SGrid xs={4} sm={4}>
          <Button
            aria-label='upload picture'
            component='span'
            {...store.styles.ButtonSecondaryB()}
            onClick={e => navigate('/')}
            size='small'
          >
            <ArrowBackIcon /> {store.lang['BACK']}
          </Button>
          <div></div>

          <Button
            aria-label='upload picture'
            component='span'
            {...store.styles.ButtonSecondaryB()}
            size='small'
            onClick={e => onClick({ action: 'EXPORT_EXCEL' })}
          >
            <CloudDownloadIcon /> {store.lang['DOWNLOAD']}
          </Button>
        </SGrid>
      </div>
    </Paper>
  )
}
*/
