import React, { useContext, useEffect, useState } from 'react'

import firebase, { auth, firestore } from '../common/firebase'
import { URL } from '../common/Config'

import { Router, navigate } from '@reach/router'

import { StoreContext } from '../contexts/StoreProvider'

import useCrud from '../hooks/UseCrud'
import useCheck from '../hooks/UseCheck'
import useFeedback from '../hooks/UseFeedBack'
import useProgress from '../hooks/UseProgress'
import SProgress from '../components/SProgress'
import LayoutBOContent from '../components/LayoutBOContent'
import Feedback from '../components/Feedback'

import SGrid from '../components/SGrid'
import Wrapper from '../components/Wrapper'
import DialogDeleteConfirm from './DialogDeleteConfirm'
import useTracker, { ERROR_ONCLICK_PAY, PAYMENT_SUCCESS } from '../hooks/useTracker';

import CheckOutView from './minibooks/checkout/CheckOutView'

const defaultState = {
  //Fields of state
}

const defaultStateError = {
  //Same as fields of state
}

const defaultStateAux = {
  //Create a entity (i.e. a Student) from a Dialog
}

const defaultClientData = {
  name: '',
  phone: '',
  email: ''
}

const defaultErrorClientData = {
  name: '',
  phone: '',
  email: ''
}

const defaultDialogConfirm = {
  show: false,
  code: ''
}

function CheckOutContainer (props) {
  const checkOutViewRef = React.useRef(null)

  const { store, dispatchStore, func } = useContext(StoreContext);
  const [handleTracker] = useTracker(store.activeTracker);

  const [
    isShowProgress,
    showProgress,
    hideProgress,
    toggleProgress
  ] = useProgress(false)

  const [
    feedback,
    handleCloseFeedback,
    showFeedback,
    hideFeedback,
    feedbackFunc,
    handleGlobalError
  ] = useFeedback({
    defaultState: {
      visible: false,
      duration: 3000,
      primaryMessage: '',
      severity: 'info'
    }
  })

  const [state, setState] = useState(defaultState)
  const [stateError, setStateError] = useState(defaultStateError)
  const [clientData, setClientData] = useState(defaultClientData)
  const [errorClientData, setErrorClientData] = useState(defaultErrorClientData)
  const [dialogConfirm, setDialogConfirm] = useState(defaultDialogConfirm)

  const [checkErrorClientData, handleCheckClientData] = useCheck({
    conditions: [
      {
        name: clientData.name,
        errorName: 'name',
        check: ['required']
      },
      {
        name: clientData.email,
        errorName: 'email',
        check: ['required', 'email']
      }
    ],
    setErrorState: setErrorClientData,
    errorState: {},
    defaultErrorState: defaultErrorClientData
  })

  const [crudPayments] = useCrud({
    arrCol: ['/payments/']
  })

  async function handleOnChange (data) {
    switch (data.action) {
      case 'ONCHANGE':
        setErrorClientData({ ...errorClientData, [data.name]: '' })
        setClientData({ ...clientData, [data.name]: data.value })

        break
    }
  } //handleOnChange

  async function handleClickDeleteProduct (id) {
    try {
      func.deleteItemFromCart(id)
      feedbackFunc.info(store.lang['DELETE_OK_TO_CART'])
    } catch (e) {
      feedback.error(store.lang['DELETE_KO_TO_CART'])
    }
  } //handleClickDeleteProduct

  async function handleClick_payment () {
    try {
      let check = handleCheckClientData()

      if (check) {
        feedbackFunc.error(store.lang['CLIENTDATA_ERROR'])
        handleTracker(ERROR_ONCLICK_PAY,{shoppingCart: store.shoppingCart, message:'Client data error'})
        return
      }
      showProgress()

      let products = store.shoppingCart.map(item => {
        return {
          id: item.id,
          title: item.title,
          filePath: item.filePath,
          nDownloads: 2
        }
      })

      let produtsToSave = {}
      for (let i = 0; i < products.length; i++) {
        produtsToSave[products[i].id] = {
          ...products[i]
        }
      }
      
      let batch = await firestore.batch()
      /**/ let paymentCreated = await crudPayments.create({
        batch,
        payload: {
          name: clientData.name,
          email: clientData.email,
          phone: clientData.phone,
          products: produtsToSave
        }
      })

      /**/
      let resPayment = await checkOutViewRef.current.pay()

      if (resPayment.error) throw resPayment

      batch
        .commit()
        .then(async res => {
          //feedbackFunc.success(store.lang['SENDATA_SUCCESS'])
          func.resetItemsToCart()
          let sendMail = firebase.functions().httpsCallable('sendMail')

          sendMail({
            action: 'PAYMENT_SUCCESS',
            name: clientData.name,
            email: clientData.email,
            url: URL + '/minibooks/payments/' + paymentCreated.id
            //url: 'http://localhost:3000/minibooks/payments/rfWusmDUpqxW7iBirKd3'
          })
          await handleTracker(PAYMENT_SUCCESS,{shoppingCart: store.shoppingCart})
          navigate('/minibooks/payments/' + paymentCreated.id)
        })
        .catch(err => {
          handleGlobalError({
            error: err,
            navigate: navigate,
            url: '/login',
            lang: store.lang
          })
        })
    } catch (error) {
      handleTracker(ERROR_ONCLICK_PAY,{error:error, shoppingCart: store.shoppingCart})
      handleGlobalError({
        error: error,
        navigate: navigate,
        url: '/login',
        lang: store.lang
      })
    }

    hideProgress()
  } //handleClick_payment

  async function handleClick (data) {
    switch (data.action) {
      case 'DELETE_PRODUCT':
        setDialogConfirm({ ...dialogConfirm, code: data.id, show: true })
        break

      case 'PAY':
        handleClick_payment()
        break
    }
  } //handleClick


  return (
    <div>
      <SProgress visible={isShowProgress} size={60} />
      <Feedback
        visible={feedback.visible}
        duration={feedback.duration}
        primaryMessage={feedback.primaryMessage}
        severity={feedback.severity}
        onClose={() => {
          handleCloseFeedback()
        }}
      />

      <DialogDeleteConfirm
        open={dialogConfirm.show}
        code={dialogConfirm.code}
        onClick={({ type, code, payload }) => {
          if (type === 'CANCEL') {
          }
          if (type === 'OK') {
            handleClickDeleteProduct(code)
          }
          setDialogConfirm(defaultDialogConfirm)
        }}
      />

      <LayoutBOContent>
        <Wrapper
          visible={true}
          compNotVisible={undefined}
          //errorMsg={props.error.field}
        >
          <CheckOutView
            name={clientData.name}
            phone={clientData.phone}
            email={clientData.email}
            errorClient={errorClientData}
            onChange={data => handleOnChange(data)}
            onClick={data => handleClick(data)}
            products={store.shoppingCart}
            amount={
              store.shoppingCart.length > 0
                ? store.shoppingCart.reduce(
                    (accumulator, currentValue) =>
                      accumulator + parseFloat(currentValue.price),
                    0
                  )
                : '0'
            }
            currency={'EUR'}
            billingDetails={{ name: clientData.name, email: clientData.email }}
            ref={checkOutViewRef}
          />
        </Wrapper>
      </LayoutBOContent>
    </div>
  )
}

export default CheckOutContainer
