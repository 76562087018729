import React from 'react'

function useStorage ({ storage, id }) {
  function setStorage (_data) {
    storage.setItem(id, JSON.stringify(_data))
  }
  function getStorage () {
    let dataRes = JSON.parse(storage.getItem(id))
    return dataRes
  }

  function deleteStorage () {
    storage.removeItem(id)
  }
  return [setStorage, getStorage, deleteStorage]
}

export default useStorage
