import React, { useState, useEffect } from 'react'

import firebase, {
  auth,
  functions,
  firestore,
  storage
} from '../common/firebase'

import { setTracking } from '../cruds/crudTracker';

import useLocalStorage from './useLocalStorage';
import useCrud from './UseCrud'
import useQueryParams from './useQueryParams';


export const CLICK_BUY_MINIBOOKS = 'CLICK_BUY_MINIBOOKS';
export const CLICK_BUY_MINIBOOKS_DETAILS = 'CLICK_BUY_MINIBOOKS_DETAILS';
export const CLICK_SHOPPING_CART = 'CLICK_SHOPPING_CART';
export const ERROR_ONCLICK_PAY = 'ERROR_ONCLICK_PAY';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';

let defaultState = {
  //date: '',
  user: '',
  message: '',
  extraInfo: {}
};

let localStorageDefault = {
  userId: `${new Date().getTime()}_${Math.round(Math.random() * 100000).toString(20)}`
};

function useTracker(active) {
  const [state, setState] = useState(defaultState);

  let [stateLocalStorage, setDataLocalStorage] = useLocalStorage(localStorageDefault, 'e40_tracking')

  useEffect(() => {
    if (!active) return;
    setState({ ...state, user: stateLocalStorage.userId })
  }, []);

  //handleTracker
  async function handleTracker(message, extraInfo) {
    if (!active) return;
    try {
      await setTracking({ user: stateLocalStorage.userId, message, extraInfo }, null);
    } catch (err) {
      console.log('err', err);
    }
  }

  return [handleTracker]
} //useTracker

export default useTracker;


