import React, { useState, useEffect } from 'react'
/**
 * visible:
 * spacing:
 * xs:
 * sm:
 * zoom:
 * gridItemStyle
 */

import Grid from '@material-ui/core/Grid'

function SGrid (props) {
  const [children, setChildren] = useState([])

  useEffect(() => {
    if (props.children && props.children.length) {
      setChildren(props.children)
    } else if (props.children) {
      setChildren([props.children])
    } else {
      setChildren([])
    }
  }, [props.children])

  if (!props.visible) return null
  return (
    <div className={props.className} style={{ zoom: props.zoom }}>
      <Grid container spacing={props.spacing}>
        {children &&
          children.map((child, key) => (
            <Grid
              style={props.gridItemStyle}
              key={key}
              item
              xs={
                child.props && child.props.sgridxs
                  ? child.props.sgridxs
                  : props.xs
              }
              sm={
                child.props && child.props.sgridsm
                  ? child.props.sgridsm
                  : props.sm
              }
            >
              {child}
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

SGrid.defaultProps = {
  visible: true,
  spacing: 0,
  xs: 3,
  sm: 3,
  className: 'gridmain',
  zoom: '100%',
  gridItemStyle: { paddingTop: '5px' }
}

export default SGrid
