import React, { useContext, useState, useEffect } from 'react'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import RowWithActions from '../../components/RowWithActions'
import TablePaginationActions from '../../components/TablePaginationActions'

import { StoreContext } from '../../contexts/StoreProvider'
import { Button } from '@material-ui/core'

function SensorTable ({
  headers = [],
  data = [],
  onClick = () => {},
  paginationTop = true,
  TableFooterComponent = null,
  ComponentTop = null
}) {
  /**
   * headers:{
   *  name:
   *  alias:
   * }
   */
  const { store, dispatchStore } = useContext(StoreContext)

  const [dataTable, setDatatTable] = useState([])

  /*useEffect(() => {
    let dataAux = []
    for (let i = 0; i < 10; i++) {
      dataAux.push(data[i])
    }
    setDatatTable(dataAux)
  })*/

  console.log('SENSORTABLE_RENDER')

  return (
    <div>
      <TableContainer component={Paper}>
        {ComponentTop}
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow style={{ background: store.styles.primaryColor }}>
              <TableCell style={{ color: 'white' }} align='left'>
                {store.lang['ACTIONS']}
              </TableCell>
              {headers &&
                headers.map(header => (
                  <TableCell
                    key={header.name}
                    style={{ color: 'white' }}
                    align='left'
                  >
                    {!header.alias || header.alias === ''
                      ? header.name
                      : header.alias}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data
                .filter((data, i) => {
                  if (i < 5000) return true
                  else return false
                })
                .map((data, key) => (
                  <TableRow key={key}>
                    <TableCell align='left'>
                      <ActionButton
                        actions={[
                          {
                            action: 'ADD_COMMENT',
                            name: store.lang['ADD_COMMENT']
                          }
                        ]}
                        onClick={_in => {
                          onClick({ action: _in.action, id: data.id })
                        }}
                      />
                    </TableCell>
                    {headers &&
                      headers.map(header => {
                        return (
                          <TableCell key={header.name} align='left'>
                            {data[header.name]}
                          </TableCell>
                        )
                      })}
                  </TableRow>
                ))}
          </TableBody>
          <TableFooter style={{ minWidth: '500px' }}>
            {TableFooterComponent}
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SensorTable

const ActionButton = ({ actions = [], onClick = () => {} }) => {
  /**
   * actions:[
   * {
   * name:
   * action:
   * }
   * ]
   */
  const { store, dispatchStore } = useContext(StoreContext)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickItem = ({ action }) => {
    onClick({ action })
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        //onClick={e => onClick({ action: 'VIEW', id: sensor.id })}
        edge='end'
        aria-label='comments'
        onClick={e => handleClick(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={e => handleClose()}
      >
        {actions &&
          actions.map(act => (
            <MenuItem
              key={act.action}
              onClick={e => handleClickItem({ action: act.action })}
            >
              {act.name}
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}
