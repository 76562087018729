import React, { useContext } from 'react'

import { StoreContext } from '../../contexts/StoreProvider'

import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import RowWithActions from '../../components/RowWithActions'
import TablePaginationActions from '../../components/TablePaginationActions'
import TableRow from '@material-ui/core/TableRow'

import SensorTable from './SensorTable'

/*
const TablePaginationTopMemo = React.memo(
  ({
    style,
    rowsPerPageOptions,
    colSpan,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage
  }) => {
    return (
      <TablePaginationTop
        style={style}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={colSpan}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
)*/

const SensorTableMemo = React.memo(({ headers, data, onClick, pagination }) => {
  return (
    <SensorTable
      headers={headers}
      data={data}
      onClick={onClick}
      /**/ ComponentTop={
        <TablePaginationTop
          style={pagination.style}
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          colSpan={1}
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onChangePage={(e, newPage) => {
            onClick({
              action: 'PAGINATION_ONCHANGEPAGE',
              payload: { newPage: newPage }
            })
          }}
          onChangeRowsPerPage={event => {
            console.log('event', event.target.value)
            onClick({
              action: 'PAGINATION_ONCHANGEROWSPERPAGE',
              payload: { rowsPerPage: parseInt(event.target.value, 10) }
            })
          }}
        />
      }
    />
  )
})

function SensorDetailsView ({
  name = '',
  hardwareSerial = '',
  description = '',
  tableHeaders = [],
  tableData = [],
  actions = null,
  onClick = () => {},
  pagination = {}
}) {
  const { store, dispatchStore } = useContext(StoreContext)

  return (
    <div>
      <div
        style={{
          fontSize: '1.2em',
          fontWeight: 'bold',
          position: 'relative',
          textAlign: 'center',
          marginBottom: '10px'
        }}
      >
        {name + ' (' + hardwareSerial + ')'}
        <div
          style={{
            fontSize: 'em',
            fontWeight: 'normal',
            position: 'relative',
            textAlign: 'center',
            marginBottom: '10px'
          }}
        >
          {description}
        </div>
      </div>
      {actions}

      <Paper>
        <div style={{ padding: '8px' }}>
          <SensorTableMemo
            pagination={pagination}
            headers={tableHeaders}
            data={tableData}
            onClick={onClick}
          />
        </div>
      </Paper>
    </div>
  )
}

export default SensorDetailsView

const TablePaginationTop = ({
  style = { overflow: 'visible' },
  rowsPerPageOptions = [5, 10, 25, { label: 'All', value: -1 }],
  colSpan = 1,
  count = 0,
  rowsPerPage = 5,
  page = 0,
  onChangePage = () => {},
  onChangeRowsPerPage = () => {}
}) => {
  const { store, dispatchStore } = useContext(StoreContext)

  return (
    <TablePagination
      style={style}
      rowsPerPageOptions={rowsPerPageOptions}
      colSpan={1}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      SelectProps={{
        inputProps: { 'aria-label': 'Rows per page' },
        native: true
      }}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  )
} //TablePaginationTop
