import React, { useEffect } from 'react';

const useLocalStore = (initialState, key) => {
  const [state, dispatch] = React.useReducer(reducer, getState());

  useEffect(() => {
    let currentState = getState();
    if (currentState) {
      dispatch({ type: 'setState', payload: currentState });
    }
  }, []);

  //reducer
  function reducer(state, action) {
    let newState = {}
    switch (action.type) {
      case 'setState':
        newState = action.payload
        localStorage.setItem(key, JSON.stringify(newState))
        return action.payload
      case 'setStateFunc':
        newState = action.payload(state)
        localStorage.setItem(key, JSON.stringify(newState))
        return newState
    }
  } //reducer

  function getState() {
    let storage = localStorage.getItem(key)
    if (!storage) {
      return initialState
    } else {
      return JSON.parse(storage)
    }
  } //getState

  function setLocalStoreState(_state) {
    if (typeof _state === 'function') {
      dispatch({ type: 'setStateFunc', payload: _state })
    } else {
      dispatch({ type: 'setState', payload: _state })
    }
  } //setState

  return [state, setLocalStoreState]
} //useLocalStore

export default useLocalStore;
