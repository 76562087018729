import React, { useState, useEffect } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import RowWithActions from '../../components/RowWithActions'
import SGrid from '../../components/SGrid'

function SensorListView ({ title = '', sensors = [], onClick = () => {} }) {
  console.log('sensors', sensors)
  return (
    <div>
      <div
        style={{
          fontSize: '1.2em',
          fontWeight: 'bold',
          position: 'relative',
          textAlign: 'center',
          marginBottom: '10px'
        }}
      >
        {title}
      </div>
      <br />
      <SGrid spacing={3} xs={12} sm={6}>
        {sensors &&
          sensors.map(sensor => (
            <div key={sensor.id}>
              <RowWithActions
                primary={sensor.name + ' (' + sensor.hardwareSerial + ')'}
                secondary={sensor.description}
                id={sensor.id}
                onDelete={() => {}}
                actions={
                  <IconButton
                    onClick={e => onClick({ action: 'VIEW', id: sensor.id })}
                    edge='end'
                    aria-label='comments'
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              />
            </div>
          ))}
      </SGrid>
    </div>
  )
}

export default SensorListView
