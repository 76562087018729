import React, { useContext } from 'react'

function Legal () {
  return (
    <div style={{ padding: '20px', color: 'rgb(179,43,129)' }}>
      <h2>AVISO LEGAL</h2>
      <h3>Identificación y Titularidad</h3>
      <p>
        En cumplimiento del artículo 10 de la Ley 34 / 2002, de 11 de julio, de
        Servicios de la Sociedad de la Información y Comercio Electrónico, el
        Titular expone sus datos identificativos.
      </p>
      <ul>
        <li>Titular: Jose Coego Miguélez</li>
        <li>NIF: 44088934G</li>
        <li>Domicilio: LG. Bais, nº2, Agolada (Pontevedra).</li>
        <li>Correo electrónico: semaforocolesanticovid@gmail.com</li>
        <li>Sitio Web: https://app.estetica40.com</li>
      </ul>

      <h3>Finalidad</h3>
      <p>
        La finalidad del sitio Web https://app.estetica40.com es ofrecer
        servicios y productos que complementen el blog
        https://www.estetica40.com.
      </p>

      <h3>Condiciones de Uso</h3>
      <p>
        La utilización del sitio Web le otorga la condición de Usuario, e
        implica la aceptación completa de todas las cláusulas y condiciones de
        uso incluidas en las páginas:
      </p>
      <ul>
        <li>Aviso Legal</li>
        <li>Política de Privacidad</li>
        <li>Política de Cookies</li>
      </ul>
      <p>
        Si no estuviera conforme con todas y cada una de estas cláusulas y
        condiciones absténgase de utilizar este sitio Web.
      </p>
      <p>
        El acceso a este sitio Web no supone, en modo alguno, el inicio de una
        relación comercial con el Titular.
      </p>
      <p>
        A través de este sitio Web, el Titular le facilita el acceso y la
        utilización de diversos contenidos que el Titular o sus colaboradores
        han publicado por medio de Internet.
      </p>
      <p>
        A tal efecto, usted está obligado y comprometido a NO utilizar
        cualquiera de los contenidos del sitio Web con fines o efectos ilícitos,
        prohibidos en este Aviso Legal o por la legislación vigente, lesivos de
        los derechos e intereses de terceros, o que de cualquier forma puedan
        dañar, inutilizar, sobrecargar, deteriorar o impedir la normal
        utilización de los contenidos, los equipos informáticos o los
        documentos, archivos y toda clase de contenidos almacenados en cualquier
        equipo informático propios o contratados por el Titular, de otros
        usuarios o de cualquier usuario de Internet.
      </p>
      <h3>Comentarios</h3>

      <p>
        El Titular se reserva el derecho de retirar todos aquellos comentarios
        que vulneren la legislación vigente, lesivos de los derechos o intereses
        de terceros, o que, a su juicio, no resulten adecuados para su
        publicación.
      </p>
      <p>
        El Titular no será responsable de las opiniones vertidas por los
        usuarios a través del sistema de comentarios, redes sociales u otras
        herramientas de participación, conforme a lo previsto en la normativa de
        aplicación.
      </p>
      <h3>Mayoría de edad</h3>
      <p>
        Usted debe tener al menos 18 años de edad para usar los servicios
        ofrecidos por el Titular o la mayoría de edad que se requiera su país
        para registrarse en el sitio Web o utilizarlo. Si reside en un país que
        no pertenezca a la Región europea, debe tener al menos 13 años de edad
        para usar el sitio Web o la mayoría de edad que se requiera su país para
        registrarse en el sitio Web o utilizarlo.
      </p>
      <p>
        Además de tener la edad mínima requerida para usar el sitio Web en
        virtud de la ley aplicable, si no tiene la edad suficiente para poder
        aceptar nuestras condiciones en su país, su padre, madre, o tutor deben
        aceptar nuestras condiciones en su nombre.
      </p>
      <h3>Medidas de seguridad</h3>
      <p>
        Los datos personales que facilite al Titular pueden ser almacenados en
        bases de datos automatizadas o no, cuya titularidad corresponde en
        exclusiva a el Titular, que asume todas las medidas de índole técnica,
        organizativa y de seguridad que garantizan la confidencialidad,
        integridad y calidad de la información contenida en las mismas de
        acuerdo con lo establecido en la normativa vigente en protección de
        datos.
      </p>
      <p>
        No obstante, debe ser consciente de que las medidas de seguridad de los
        sistemas informáticos en Internet no son enteramente fiables y que, por
        tanto el Titular no puede garantizar la inexistencia de virus u otros
        elementos que puedan producir alteraciones en los sistemas informáticos
        (software y hardware) del Usuario o en sus documentos electrónicos y
        ficheros contenidos en los mismos aunque el Titular pone todos los
        medios necesarios y toma las medidas de seguridad oportunas para evitar
        la presencia de estos elementos dañinos.
      </p>
      <h3>Datos personales</h3>
      <p>
        Usted puede consultar toda la información relativa al tratamiento de
        datos personales que recoge el Titular en la página de la Política de
        Privacidad.
      </p>
      <h3>Contenidos</h3>
      <p>
        El Titular ha obtenido la información, el contenido multimedia y los
        materiales incluidos en el sitio Web de fuentes que considera fiables,
        pero, si bien ha tomado todas las medidas razonables para asegurar que
        la información contenida es correcta, el Titular no garantiza que sea
        exacta, completa o actualizada. El Titular declina expresamente
        cualquier responsabilidad por error u omisión en la información
        contenida en las páginas de este sitio Web.
      </p>
      <p>
        Queda prohibido transmitir o enviar a través del sitio Web cualquier
        contenido ilegal o ilícito, virus informáticos, o mensajes que, en
        general, afecten o violen derechos de el Titular o de terceros.
      </p>
      <p>
        Los contenidos del Sitio Web tienen únicamente una finalidad informativa
        y bajo ninguna circunstancia deben usarse ni considerarse como oferta de
        venta, solicitud de una oferta de compra ni recomendación para realizar
        cualquier otra operación, salvo que así se indique expresamente.
      </p>
      <p>
        El Titular se reserva el derecho a modificar, suspender, cancelar o
        restringir el contenido del Sitio Web, los vínculos o la información
        obtenida a través del sitio Web, sin necesidad de previo aviso.
      </p>
      <p>
        El Titular no es responsable de los daños y perjuicios que pudieran
        derivarse de la utilización de la información del sitio Web o de la
        contenida en las redes sociales del Titular.
      </p>
    </div>
  )
} //Legal

export default Legal
