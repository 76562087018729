import React, { useState } from 'react'

export default function useCheck ({
  conditions,
  setErrorState,
  errorState,
  defaultErrorState
}) {
  const [isError, setIsError] = useState(false)

  function handleCheck () {
    let check = checkForm(conditions)

    if (check.error) {
      let { error, ...checkAux } = check
      setIsError(true)
      setErrorState({ ...errorState, ...checkAux })
      return true
    } else {
      setIsError(false)
      setErrorState(defaultErrorState)
      return false
    }
  } //handleCheck

  return [isError, handleCheck]
} //useCheck

export function checkForm (_arrField) {
  /**
   *
   * _arrField.name
   * _arrField.errorName : Field name of the field that isnt right
   * _arrField.check[i]=['requierd','mail',['>=',3,'message']]
   */
  let objRes = {
    error: false
  }
  for (let k = 0; k < _arrField.length; k++) {
    let _field = _arrField[k]
    let _arrFields = _field.check

    let next = true
    for (let i = 0; i < _arrFields.length && next; i++) {
      if (typeof _arrFields[i] === 'string') {
        switch (_arrFields[i]) {
          case 'required':
            if (!_field.name) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'required_field'
              }

              next = false
            }
            break

          case 'email':
            let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            /* eslint-enable no-useless-escape */
            if (!pattern.test(_field.name)) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'wrong_mail_format'
              }
              next = false
            }
            break
          case 'number':
            if (!_field.name || _field.name === '' || isNaN(_field.name)) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: 'notNumber_field'
              }

              next = false
            }
            break

          default:
        }
      } else if (Array.isArray(_arrFields[i])) {
        switch (_arrFields[i][0]) {
          case '=':
            //console.log('==', _field.name, _arrFields[i][1])
            if (!(_field.name === _arrFields[i][1])) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]:
                  _arrFields[i].length === 3
                    ? _arrFields[i][2]
                    : 'generic_error_field'
              }
              next = false
            }
            break

          case '>':
            if (!(parseFloat(_field.name) > _arrFields[i][1])) {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]:
                  _arrFields[i].length === 3
                    ? _arrFields[i][2]
                    : 'generic_error_field'
              }
              next = false
            }

            break

          case 'function':
            /**
             * primer parametro del array:function
             * segundo parametro del array: funcion a ejecutar
             * tercer parámetro del array: parametros de la función a ejecutar
             * La función devuelve '' si no hay error y el mensaje de error si hay error
             */
            let functionReturnValue = _arrFields[i][1](_arrFields[i][2])

            if (functionReturnValue !== '') {
              objRes = {
                ...objRes,
                error: true,
                [_field.errorName]: functionReturnValue
              }
              next = false
            }
            break
        }
      }
    }
  }

  return objRes
} //checkForm
