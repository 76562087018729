import React, { useContext, forwardRef } from 'react'

import firebase, { auth, firestore } from '../../../common/firebase'

import Button from '@material-ui/core/Button'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { StoreContext } from '../../../contexts/StoreProvider'
import SGrid from '../../../components/SGrid'

const cardElementStyle = {
  backgroundColor: '#ebebf5',
  height: '40px',
  width: '100%',
  paddingTop: '20px',
  paddingLeft: '7px'
}

function CheckOutCreditCard(
  { amount = 0, currency = 'EUR', billingDetails = {}, onClick = () => { } },
  ref
) {
  const { store, dispatchStore } = useContext(StoreContext)

  const stripe = useStripe()
  const elements = useElements()

  async function handleClickPay() {
    try {
      const cardElement = elements.getElement('card')
      var paymentIntent = firebase.functions().httpsCallable('paymentIntent')
      let clientSecret = await paymentIntent({
        amount: amount,
        currency: currency
      })
      //console.log('result', result.data.pi.client_secret)
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails
        /*{
          name: 'Jose',
          email: 'jose@mail.com',
          phone: '987898988'
        }*/
      })

      if (paymentMethodReq.error) {
        return { error: true, message: 'payment_error' }
      }

      let resConfirm = await stripe.confirmCardPayment(
        clientSecret.data.pi.client_secret,
        {
          payment_method: paymentMethodReq.paymentMethod.id
        }
      )
      //console.log('success_checkoutCreditCard')
      return { error: false, message: 'payment_success' }
    } catch (err) {
      
      return { error: true, message: 'payment_error' }
    }
  } //handleClickPay

  const cardElementOpts = {
    iconStyle: 'solid',
    style: {
      /**/ base: {
        //color: 'red',
        //fontSize: '16px',
        //iconColor: '#fff',
        '::placeholder': {
          color: 'blue'
        }
      },
      invalid: {
        iconColor: 'red',
        color: 'red'
      },
      complete: {
        iconColor: 'green',
        color: 'green'
      }
    },
    hidePostalCode: true
  }

  React.useImperativeHandle(ref, () => ({
    pay: async () => {
      let res = await handleClickPay()
      return res
    }
  }))

  return (
    <div>
      <div>
        <SGrid xs={12} sm={6}>
          <div style={cardElementStyle}>
            <CardElement options={cardElementOpts} />
          </div>
          <div></div>
        </SGrid>
      </div>
      <br />

      <Button
        style={{
          background: amount > 0 ? 'rgb(173,29,143' : 'grey',
          color: 'white',
          width: '100%'
        }}
        disabled={amount <= 0}
        onClick={e => onClick({ action: 'PAY' })}
      >
        <strong>{store.lang['PAY'] + '  ' + amount + ' €'}</strong>
      </Button>
    </div>
  )
}

CheckOutCreditCard = forwardRef(CheckOutCreditCard)
export default CheckOutCreditCard
